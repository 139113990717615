import React, { useEffect, useState } from "react";
import "./Users.scss";
import { useNavigate } from "react-router-dom";
import { apiGet, apiDelete, apiPost, apiPut } from "../../../Utilities/userAuth";
import { apiResponse, handleError } from "../../../Utilities/customHelper";
import { getStorage, flushStorage } from "../../../Utilities/storage";
import PaymentLoader from "../../../Shared/Loaders/PaymentLoader";
import { MdDelete, MdEdit } from "react-icons/md";
import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import Spinner from "../../../Shared/Loaders/Spinner";
import { Outlet, useLocation, Navigate } from "react-router-dom";

const Users = () => {
  let userInfo = getStorage("userInfo");
  const navigate = useNavigate();
  const { REACT_APP_API_URL } = process.env;
  const [getAllUsers, setGetAllUsers] = useState([]);
  const [getOneUsers, setGetOneUsers] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [deleteUser, setDeleteUser] = useState();
  const [editUser, setEditUser] = useState();
  const [priLoading, setPrivLoading] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [innerPage, setInnerPage] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const { pathname } = useLocation();
  const countString = (str, letter) => {
    const re = new RegExp(letter, "g");

    const count = str.match(re).length;

    return count;
  };

  let letterToCheck = "/";

  const result = countString(pathname, letterToCheck);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    if (pathname.includes("company")) {
      setInnerPage(false);
    } else {
      if (result > 1) {
        setInnerPage(true);
      } else {
        setInnerPage(false);
      }
    }
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const recordsPerPage = 10;
  const indexOfLastRecord = (currentPage + 1) * recordsPerPage; // currentPage starts from 0
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = getAllUsers.slice(indexOfFirstRecord, indexOfLastRecord);

  useEffect(() => {
    getUsers();
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      // email: "",
      // password: "",
    },
  });

  const {
    register: editRegister,
    reset: editRegisterReset,
    handleSubmit: editHandleSubmit,
    watch: editWatch,
    formState: { errors: editError, isSubmitSuccessful: EditSubmitSuccessful },
    setValue: setValueUser,
  } = useForm({
    defaultValues: {
      // email: "",
      // password: "",
    },
  });

  const password = watch("password");
  const editPassword = editWatch("password");

  let getUsers = () => {
    setIsLoading(true);
    if (userInfo && userInfo.token) {
      let url = `${REACT_APP_API_URL}customer/`;
      const params = {};
      apiGet(url, params, true)
        .then((response) => {
          if (response.data.success === true) {
            let valuesFromDb = response.data.payload.records;
            setGetAllUsers(valuesFromDb);
            setIsLoading(false);
            setTotalPages(Math.ceil(response.data.payload.totalRecords / 10));
          } else {
            if (response.data.success === false) {
              setIsLoading(false);
              console.log(response.data.message);
            }
            apiResponse(response, navigate);
          }
        })
        .catch((error) => {
          handleError();
        });
    } else {
      navigate("/login");
    }
  };

  const createAccount = (formData) => {
    setIsLoading(true);
    let token = userInfo && userInfo.token;
    console.log(token);
    let url = `${REACT_APP_API_URL}/customer`;
    const params = {
      name: formData.name,
      email: formData.email,
      password: formData.password,
      // token: token,
    };

    apiPost(url, params, true)
      .then((response) => {
        if (response.data.success === true) {
          setIsLoading(false);
          toast.success(response.data.message);
          getUsers();
          reset();
          document.getElementById("createUserModal").click();
        } else {
          if (response.status === 400 || response.status === 401) {
            toast.error(response.data.message);
          } else if (response.status === 422) {
            const errorMessage = response.data.errors[0].message;
            toast.error(errorMessage);
            setIsLoading(false);
          } else {
            let msg = "Response Error! Please try again later.";
            toast.error(msg);
          }
          setIsLoading(false);
        }
      })
      .catch((error) => {
        let msg = "Something Wrong! Please try after some time.";
        toast.error(msg);
      })
      .finally(() => {
        setPrivLoading(false);
      });
  };
  const deleteCustomer = () => {
    const id = deleteUser;

    if (userInfo && userInfo.token) {
      let url = `${REACT_APP_API_URL}customer/${id}`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: userInfo.token,
      };

      apiDelete(url, headers, true)
        .then((response) => {
          if (response.data.success === true) {
            setIsLoading(false);
            toast.success(response.data.message);
            document.getElementById("closeDeleteModal").click();
            getUsers();
          } else {
            apiResponse(response, navigate);
          }
        })
        .catch((error) => {
          handleError();
        });
    } else {
      navigate("/login");
    }
  };

  let getUserOne = (id) => {
    setIsLoading(true);
    if (userInfo && userInfo.token) {
      let url = `${REACT_APP_API_URL}customer/${id}`;
      const params = {};
      apiGet(url, params, true)
        .then((response) => {
          if (response.data.success === true) {
            let valuesFromDb = response.data.payload;
            console.log(valuesFromDb);
            setGetOneUsers(valuesFromDb);
            setValueUser("name", valuesFromDb.name);
            setIsLoading(false);
          } else {
            if (response.data.success === false) {
              setIsLoading(false);
              console.log(response.data.message);
            }
            apiResponse(response, navigate);
          }
        })
        .catch((error) => {
          handleError();
        });
    } else {
      navigate("/login");
    }
  };
  const UpdateUser = (formData) => {
    const id = editUser;
    console.log(formData);
    let url = `${REACT_APP_API_URL}customer/${id}`;
    let data = new FormData(); // Rename this to 'data' or another unique name
    data.append("name", formData.name);
    if (formData.password !== "") {
      data.append("password", formData.password);
    } else {
    }

    apiPut(url, data, true)
      .then((response) => {
        if (response.data.success === true) {
          toast.success(response.data.message);
          getUsers();
          document.getElementById("updateUserModal").click();
        } else {
          if (response.status === 400 || response.status === 401) {
            toast.error(response.data.message);
          } else if (response.status === 422) {
            toast.error(response.data.message);
          } else {
            let msg = "Response Error! Please try again later.";
            toast.error(msg);
          }
        }
      })
      .catch((error) => {
        let msg = "Something Wrong! Please try after some time.";
        toast.error(msg);
      });
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="kitchen-padd">
      <Toaster />
      <div className="recipe-main">
        <div className="credit-points">
          <div className="saved-recipe all-users">
            <div>
              <h5>All Users</h5>
            </div>
            <div>
              <button type="button" class="btn-custom" data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{ border: "none" }}>
                + Add New User
              </button>
            </div>
          </div>
          <div className={windowWidth > "768px" ? "history-table" : "history-table tg-scroll-x"} style={{ fontFamily: "poppins" }}>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" style={{ padding: "15px 0px 15px 30px" }}>
                    User Name
                  </th>
                  <th scope="col" style={{ textAlign: "center", padding: "15px 0px 15px 0px" }}>
                    Email
                  </th>
                  <th scope="col" style={{ textAlign: "center", padding: "15px 0px 15px 0px" }}>
                    Available Credits
                  </th>
                  <th scope="col" style={{ textAlign: "center", padding: "15px 0px 15px 0px" }}>
                    Used Credits
                  </th>
                  <th scope="col" style={{ textAlign: "center", padding: "15px 0px 15px 0px" }}>
                    Remaining Credits
                  </th>
                  <th scope="col" style={{ textAlign: "center", padding: "15px 0px 15px 0px" }}>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="7" style={{ textAlign: "center" }}>
                      <PaymentLoader />
                    </td>
                  </tr>
                ) : currentRecords && currentRecords.length > 0 ? (
                  currentRecords.map((item, idx) => (
                    <tr key={idx} className="table-tr">
                      <th style={{ textAlign: "left" }}>{item?.name ?? " -"}</th>

                      <td style={{ textAlign: "center" }}>{item?.email ?? "-"}</td>
                      <td style={{ textAlign: "center" }}>{item?.in_points ?? "-"}</td>
                      <td style={{ textAlign: "center" }}>{item.out_point ?? "-"}</td>
                      <td style={{ textAlign: "center" }}>{parseInt(item?.in_points) - parseInt(item?.out_point) ?? " -"}</td>

                      <td className="d-flex" style={{ textAlign: "center" }}>
                        <button
                          type="button"
                          class="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#editUser"
                          onClick={() => {
                            setEditUser(item.id);
                            getUserOne(item.id);
                          }}
                        >
                          <MdEdit />
                        </button>
                        &nbsp;
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#deleteUser" onClick={() => setDeleteUser(item.id)}>
                          <MdDelete />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      No Payments Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="pagination-controls d-flex">
            <button className="btn-ctm-1" onClick={handlePreviousPage} disabled={currentPage === 0}>
              Previous
            </button>
            <div className="mx-4 align-self-center">
              Page {currentPage + 1} of {totalPages}{" "}
            </div>
            <button className={`btn-ctm-1`} onClick={handleNextPage} disabled={currentPage >= totalPages - 1}>
              Next
            </button>
          </div>
          <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="staticBackdropLabel">
                    Create User
                  </h1>
                  <button type="button" class="btn-close" id="createUserModal" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <form onSubmit={handleSubmit(createAccount)}>
                    <div className="mb-3 position-relative">
                      <label>
                        Full Name <span>*</span>
                      </label>
                      <input type="name" className="form-control kitchen-input" name="name" {...register("name", { required: "This field is required" })} placeholder="Enter your Full Name" />
                      <p className="text-danger fs-6 text-start">{errors.name?.message}</p>
                    </div>
                    <div className="mb-3 position-relative">
                      <label>
                        Email <span>*</span>
                      </label>
                      <input type="email" className="form-control kitchen-input" name="email" {...register("email", { required: "This field is required" })} placeholder="Enter your email address" />
                      <p className="text-danger fs-6 text-start">{errors.email?.message}</p>
                    </div>
                    <div className="mb-3 position-relative">
                      <label>
                        Password <span>*</span>
                      </label>
                      <input
                        className="form-control kitchen-input"
                        type="password"
                        name="password"
                        placeholder="Enter your password"
                        {...register("password", {
                          required: "This field is required!",
                          minLength: {
                            value: 8,
                            message: "Password must have at least 8 characters",
                          },
                        })}
                      />
                      <p className="text-danger fs-6 text-start">{errors.password?.message}</p>
                      <span className="password-toggle-icon"></span>
                    </div>

                    <div className="mb-3 position-relative">
                      <label>
                        Confirm Password <span>*</span>
                      </label>
                      <input
                        className="form-control kitchen-input"
                        name="confirmPassword"
                        type="password"
                        placeholder="Enter your Confirm Password"
                        {...register("confirmPassword", {
                          validate: (value) => value === password || "The passwords do not match",
                        })}
                      />
                      <p className="text-danger fs-6 text-start">{errors.confirmPassword?.message}</p>
                      <span className="password-toggle-icon"></span>
                    </div>

                    <div className="d-grid mb-2">
                      <div className="login-btn">
                        <button type="submit" className="btn  btn-lg btn-custom">
                          {isLoading ? <Spinner /> : "Create User"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="modal fade" id="deleteUser" tabIndex="-1" aria-labelledby="deleteUserLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="deleteUserLabel">
                    Delete User?
                  </h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">Are You Sure you want to delete this user?</div>
                <div class="modal-footer d-flex">
                  <button type="button" class="btn" id="closeDeleteModal" data-bs-dismiss="modal">
                    Cancel
                  </button>
                  <button type="button" class="btn btn-custom" style={{ width: "auto" }} onClick={deleteCustomer}>
                    Delete User
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal fade" id="editUser" tabIndex="-1" aria-labelledby="editUserLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title fs-5" id="editUserLabel">
                    Update User
                  </h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="updateUserModal"></button>
                </div>
                <div class="modal-body">
                  <form onSubmit={editHandleSubmit(UpdateUser)}>
                    <div className="mb-3 position-relative">
                      <label>
                        Full Name <span>*</span>
                      </label>
                      <input type="text" className="form-control kitchen-input" placeholder="Enter your Full Name" {...editRegister("name", { required: "Full name is required!" })} />
                      <p className="text-danger fs-6 text-start">{editError.name?.message}</p>
                    </div>

                    <div className="mb-3 position-relative">
                      <label>Password</label>
                      <input
                        className="form-control kitchen-input"
                        type="password"
                        name="password"
                        placeholder="Enter your password"
                        {...editRegister("password", {
                          minLength: {
                            value: 8,
                            message: "Password must have at least 8 characters",
                          },
                        })}
                      />
                      <p className="text-danger fs-6 text-start">{errors.password?.message}</p>
                      <span className="password-toggle-icon"></span>
                    </div>

                    <div className="mb-3 position-relative">
                      <label>Confirm Password</label>
                      <input
                        className="form-control kitchen-input"
                        name="confirmpassword"
                        type="password"
                        placeholder="Enter your Confirm Password"
                        {...editRegister("confirmPassword", {
                          validate: (value) => value === editPassword || "The passwords do not match",
                        })}
                      />
                      <p className="text-danger fs-6 text-start">{errors.confirmPassword?.message}</p>
                      <span className="password-toggle-icon"></span>
                    </div>

                    <div className="d-grid mb-2">
                      <div className="login-btn">
                        <button type="submit" className="btn btn-lg btn-custom">
                          {isLoading ? <Spinner /> : "Update User"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Users;
