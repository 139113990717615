import React, { useState, useEffect } from "react";
import "./Dashboard.scss";
import used from "../../../Assets/images/used.png";
import SavedRecipies from "./Components/SavedRecipies";
import CreditPoints from "../Credits/Components/CreditPoints";
import { Link } from "react-router-dom";

const Dashboard = () => {
  return (
    <>
      <div className="kitchen-padd">
        <div className="row">
          <div className="col-lg-8">
            <CreditPoints />

            {/* Credit Points Section */}
            {/* <div className="credit-points">
                <h5>Your Credit Points</h5>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="">
                      <p>We have aimed to show you everything that is relevant to you in one handy place</p>
                      <div className="points d-flex">
                        <div className="total-credit" style={{ paddingRight: "16px" }}>
                          <p>Total Credit Points</p>
                          <p>1320</p>
                        </div>
                        <div className="total-credit" style={{ paddingLeft: "16px", borderRightWidth: "0px" }}>
                          <p>Total Credit Points</p>
                          <p>1320</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="points-percentage">
                      <img src={used} alt="used" />
                    </div>
                  </div>
                </div>
              </div> */}

            {/* Want More Section */}
            <div className="want-more">
              <h5>Need More Recipe Credits?</h5>
              <p>Get more cooking inspiration! Click "Buy Now" to purchase additional recipe credits and continue creating delicious dishes</p>
              <Link to={"/payments"}>
                <button type="button" style={{ marginTop: "22px" }}>
                  Buy Now
                </button>
              </Link>
            </div>
          </div>
          <div className="col-lg-4">
            <SavedRecipies />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
