import React, { useState, useEffect } from "react";
import "./Payments.scss";
import { ReactSVG } from "react-svg";
import stripe from "../../../Assets/svgs/stripe.svg";
import { Link, useNavigate } from "react-router-dom";
import { apiGet } from "../../../Utilities/userAuth";
import { apiResponse, handleError } from "../../../Utilities/customHelper";
import { getStorage, flushStorage } from "../../../Utilities/storage";
import CardDetailsForm from "./Components/CardDetailsForm";
import PaymentLoader from "../../../Shared/Loaders/PaymentLoader";

const Payments = () => {
  let userInfo = getStorage("userInfo");
  const navigate = useNavigate();
  const { REACT_APP_API_URL } = process.env;
  const [showAll, setShowAll] = useState(false);
  const [getPayments, setGetPayments] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const recordsPerPage = 10;
  const indexOfLastRecord = (currentPage + 1) * recordsPerPage; // currentPage starts from 0
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = getPayments.slice(indexOfFirstRecord, indexOfLastRecord);

  const [date, setDate] = useState("");
  const handleChange = (event) => {
    setDate(event.target.value);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    getAllPayments();
  }, [currentPage]); // Re-fetch when currentPage changes

  let getAllPayments = () => {
    setIsLoading(true);
    if (userInfo && userInfo.token) {
      let url = `${REACT_APP_API_URL}payment/`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: userInfo.token,
      };
      const params = {
        // page: currentPage, // Current page index
        // size: 10, // Number of records per page
      };
      apiGet(url, params, true)
        .then((response) => {
          if (response.data.success === true) {
            let valuesFromDb = response.data.payload.records;
            setGetPayments(valuesFromDb);
            setIsLoading(false);
            setTotalPages(Math.ceil(response.data.payload.totalRecords / 10));
          } else {
            setIsLoading(false);
            apiResponse(response, navigate);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        });
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <div className="payments-main kitchen-padd">
        {userInfo && userInfo.role == "CUSTOMER" ? (
          <>
            <div className="credits-label">
              <h3>$1/100 Credits</h3>
            </div>
            <div className="payments credit-points">
              <h3>Cards</h3>
              <div className="card-details mt-2">
                <h5 className="base-heading">Number On Card</h5>

                <CardDetailsForm getAllPayments={getAllPayments} />
              </div>
            </div>
            <div className="stripe">
              <p>
                Powered by &nbsp; <ReactSVG src={stripe} />
              </p>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="payment-history credit-points">
          <h3>Payment History</h3>
          <div className="history-table">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" style={{ padding: "15px 0px 15px 30px" }}>
                    Order Number
                  </th>
                  {userInfo && userInfo.role === "ADMIN" ? (
                    <th scope="col" style={{ textAlign: "center", padding: "15px 0px 15px 0px" }}>
                      User Name
                    </th>
                  ) : (
                    ""
                  )}
                  <th scope="col" style={{ textAlign: "center", padding: "15px 0px 15px 0px" }}>
                    Type
                  </th>
                  <th scope="col" style={{ textAlign: "center", padding: "15px 0px 15px 0px" }}>
                    Date
                  </th>
                  <th scope="col" style={{ textAlign: "center", padding: "15px 0px 15px 0px" }}>
                    Credits
                  </th>
                  <th scope="col" style={{ textAlign: "center", padding: "15px 0px 15px 0px" }}>
                    Payment
                  </th>

                  <th scope="col" style={{ textAlign: "center", padding: "15px 0px 15px 0px" }}>
                    Card
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="7" style={{ textAlign: "center" }}>
                      <PaymentLoader />
                    </td>
                  </tr>
                ) : currentRecords && currentRecords.length > 0 ? (
                  currentRecords.map((item, idx) => (
                    <tr key={idx} className="table-tr">
                      <th style={{ textAlign: "center" }}>{item && item.description ? item.description : " --"}</th>
                      {userInfo && userInfo.role === "ADMIN" ? (
                        <>
                          <td style={{ textAlign: "center" }}>{item?.users.name ?? " -"}</td>
                        </>
                      ) : (
                        ""
                      )}
                      <td style={{ textAlign: "center" }}>Invoice</td>
                      <td style={{ textAlign: "center" }}>{item && item.created_at && item.created_at.slice(0, 10)}</td>
                      <td style={{ textAlign: "center" }}>{item && item.points ? item.points : " -"}</td>
                      <td style={{ textAlign: "center" }}>${item?.amount ?? " -"}</td>

                      <td style={{ textAlign: "center" }}>
                        {JSON.parse(item.response)?.payment_method_details?.card?.last4 ? `Ended with ${JSON.parse(item.response).payment_method_details.card.last4}` : "Not Available"}
                        {/* Ended with 7765 */}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      No Payments Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="pagination-controls d-flex">
              <button className="btn-ctm-1" onClick={handlePreviousPage} disabled={currentPage === 0}>
                Previous
              </button>
              <div className="mx-4 align-self-center">
                Page {currentPage + 1} of {totalPages}{" "}
              </div>
              <button className={`btn-ctm-1`} onClick={handleNextPage} disabled={currentPage >= totalPages - 1}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payments;
