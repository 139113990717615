import React from "react";
import logoImage from "../../Assets/images/logo.png";

const Logo = () => {
  return (
    <>
      <img src={logoImage} alt="logo" />
    </>
  );
};
export default Logo;
