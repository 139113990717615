import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const PaymentLoader = () => {
  return (
    <SkeletonTheme>
      <div className="d-flex">
        {/* {[1, 2, 3, 4, 5].map(() => ( */}
        <div className="col-sm-12 px-0">
          <div className="me-2">
            <Skeleton style={{ width: "100%" }} count={3} height={50} borderRadius={10}></Skeleton>
          </div>
        </div>
        {/* ))} */}
      </div>
    </SkeletonTheme>
  );
};

export default PaymentLoader;
