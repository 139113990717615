import React, { useState, useEffect } from "react";
import "./Auth.scss";
import "../../../custom-styles.scss";
import { FaRegEyeSlash } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaRegEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { apiPost } from "../../../../src/Utilities/userAuth";
import toast, { Toaster } from "react-hot-toast";
import { setStorage, getStorage, flushStorage } from "../../../../src/Utilities/storage";
import Logo from "../../../Shared/Logo/Logo";
import Spinner from "../../../Shared/Loaders/Spinner";

function Login() {
  const { REACT_APP_API_URL } = process.env;
  const [alert, setAlert] = useState();
  const [wrong, setWrong] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      // email: "",
      // password: "",
    },
  });

  useEffect(() => {
    let userInfo = getStorage("userInfo");
    if (userInfo && userInfo.token) {
      window.location.href = "/dashboard";
    }
  }, [navigate]);

  const hideAlert = () => {
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  };

  const Login = (data) => {
    setIsLoading(true);
    let url = `${REACT_APP_API_URL}auth/login`;
    const params = {
      email: data.email,
      password: data.password,
    };
    apiPost(url, params)
      .then((response) => {
        setIsLoading(false);
        if (response.data.success) {
          const { user, accessToken } = response.data.payload;
          user.token = accessToken;
          setStorage("userInfo", user);
          setStorage("token", accessToken);
          toast.success(response.data.message);
          const storedUserInfo = getStorage("userInfo");
          if (storedUserInfo.role === "CUSTOMER") {
            window.location.href = "/generate-recipe";
          } else if (storedUserInfo.role === "ADMIN") {
            window.location.href = "/users";
          }
        } else {
          // Handle non-success scenarios
          toast.error(response.data.message);
          setAlert(response.data.message);
          hideAlert();
          flushStorage();
        }
      })
      .catch((error) => {
        // Handle errors from the API call
        flushStorage();
        let msg = "Something is wrong, please try after some time.";
        toast.error(msg);
        console.error("Error during API call:", error);
      });
  };

  return (
    <div className="auth-login">
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <div className="login-main">
          <Toaster />
          <div className="d-flex justify-content-center "></div>
          <h2 className="card-title text-center mb-3">
            <Link to={"/generate-recipe"}>
              <Logo />
            </Link>
          </h2>
          <p className="card-text text-center mb-4">Login to your Account</p>
          <form onSubmit={handleSubmit(Login)}>
            <div className="mb-3 position-relative">
              <label>
                Email <span>*</span>
              </label>
              <input type="email" className="form-control kitchen-input" name="email" {...register("email", { required: "This field is required" })} placeholder="Enter your email address" />
              <p className="text-danger fs-6 text-start">{errors.email?.message}</p>
            </div>
            <div className="mb-3 position-relative">
              <label>
                Password <span>*</span>
              </label>

              <input className="form-control kitchen-input" name="password" type={showPassword ? "text" : "password"} placeholder="Enter your password" {...register("password", { required: "This field is required!" })} />
              <p className="text-danger fs-6 text-start">{errors.password?.message}</p>
              <span className="password-toggle-icon "></span>
            </div>
            <div className="d-flex justify-content-between">
              <div className="mb-3 form-check">
                <input type="checkbox" className="form-check-input" id="rememberMeCheck" />
                <label className="form-check-label text-muted" htmlFor="rememberMeCheck">
                  Remember me
                </label>
              </div>
              <div className="text-center  mb-3">
                <Link to="/forgot-password" className="text-primary">
                  Forgot Password?
                </Link>
              </div>
            </div>
            <div className="d-grid mb-2">
              <div className="login-btn">
                <button type="submit" className="btn btn-primary btn-lg btn-custom">
                  {isLoading ? <Spinner /> : "Login"}
                </button>
              </div>
            </div>
            <div className="d-grid create-account">
              <p>
                Don't have an account?{" "}
                <Link to={"/signup"}>
                  <span className="text-primary"> Create an account</span>
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
