import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const RecipeLoader = () => {
  return (
    <SkeletonTheme>
      {[1, 2, 3, 4, 5].map(() => (
        <div className="col-sm-3 px-0">
          <div className="my-3 me-2">
            <Skeleton style={{ width: "80%" }} count={1} height={250} borderRadius={10}></Skeleton>
          </div>
        </div>
      ))}
    </SkeletonTheme>
  );
};

export default RecipeLoader;
