import React, { Component } from "react";
import axios from "axios";
import { getStorage, flushStorage } from "../Utilities/storage";

export async function apiDelete(url, params, headers) {
  let userInfo = getStorage("userInfo");
  let authorizedHeaders = "";

  if (headers) {
    authorizedHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userInfo.token}`,
    };
  } else {
    authorizedHeaders = "";
  }
  return axios
    .delete(url, {
      params,
      headers: authorizedHeaders,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function apiGetOne(url, params) {
  return axios
    .get(url, { params: params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function apiGet(url, params, headers) {
  let userInfo = getStorage("userInfo");
  let authorizedHeaders = "";
  if (headers) {
    authorizedHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userInfo.token}`,
    };
  } else {
    authorizedHeaders = "";
  }
  return axios
    .get(url, {
      params,
      headers: authorizedHeaders,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}
export async function apiGetAll(url, params) {
  return axios
    .get(url, { params: params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

// export async function apiPost(url, params, headers) {
//   let userInfo = getStorage("userInfo");
//   let authorizedHeaders = "";
//   if (headers) {
//     authorizedHeaders = {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${userInfo.token}`,
//     };
//   } else {
//     authorizedHeaders = "";
//   }
//   return axios
//     .post(url, {
//       params,
//       headers: authorizedHeaders,
//     })
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       return error.response;
//     });
// }

export async function apiPost(url, params, headers) {
  let userInfo = getStorage("userInfo");
  let authorizedHeaders = {};
  if (headers) {
    authorizedHeaders = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userInfo.token}`,
    };
  }
  return axios
    .post(url, params, { headers: authorizedHeaders })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export async function apiPut(url, params, headers) {
  let userInfo = getStorage("userInfo");
  let authorizedHeaders = {};
  if (headers) {
    authorizedHeaders = {
      "Content-Type": "application/json",
      // "Content-Type": "multipart/form-data",

      Authorization: `Bearer ${userInfo.token}`,
    };
  }
  return axios
    .put(url, params, { headers: authorizedHeaders })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export default class userAuth extends Component {
  render() {
    return <div>webapi</div>;
  }
}
