import React from "react";

const Spinner = () => {
  return (
    <>
      {[1, 2, 3].map((i) => (
        <div className="spinner-grow" role="status" style={{ width: "15px", height: "15px", marginRight: "5px" }}>
          <span className="visually-hidden">Loading...</span>
        </div>
      ))}
    </>
  );
};
export default Spinner;
