import React, { useState, useEffect, useRef } from "react";
import { apiPost, apiPut } from "../../../Utilities/userAuth";
import { setStorage, getStorage, flushStorage } from "../../../Utilities/storage";
import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { apiResponse, handleError } from "../../../Utilities/customHelper";
import "./EditProfile.scss";

const EditProfile = () => {
  const userInfo = getStorage("userInfo");
  const [editProfile, setEditProfile] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRetype, setShowPasswordRetype] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPassword, setIsLoadingPassword] = useState(false);
  const [profilephoto, setProfilePhoto] = useState("");
  const [addSelectedOption, setAddSelectedOption] = useState([]);
  let imgRef = useRef();
  const [image, setImage] = useState();

  const navigate = useNavigate();
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_API_PUBLIC_URL } = process.env;

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { isSubmitted, errors },
  } = useForm({
    shouldFocusError: true,
    keepValues: true,
    defaultValues: {
      name: userInfo && userInfo.name ? userInfo.name : "",
    },
  });
  const password = watch("password");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProfilePhoto(file);
  };

  let updateUserProfile = (data) => {
    setIsLoading(true);

    // Retrieve userInfo from local storage
    let userInfo = getStorage("userInfo");

    userInfo.name = data.name;
    if (data.profile_image) {
      userInfo.image = data.profile_image;
    }

    // Prepare form data for API call
    const formData = new FormData();
    formData.append("name", data.name);

    if (data.password) {
      formData.append("password", data.password);
    }
    if (data.email) {
      formData.append("email", data.email);
    }

    // Check if a new profile photo has been set and append it to formData
    if (profilephoto) {
      formData.append("profile_img", profilephoto);
    }

    const url = `${REACT_APP_API_URL}customer/update`;
    apiPut(url, formData, true)
      .then((response) => {
        if (response.data.success === true) {
          // Update userInfo in local storage
          setStorage("userInfo", userInfo);
          toast.success(response.data.message);
          setIsLoading(false);
        } else {
          apiResponse(response, navigate);
        }
      })
      .catch((error) => {
        console.error("Error updating profile:", error);
      });
  };

  let removeProfileImg = () => {
    var removeProfileImg = require("../../../Assets/images/user-avatar.png");
    imgRef.current.src = removeProfileImg;
    setProfilePhoto("");
  };

  useEffect(() => {
    if (userInfo && userInfo.user_info && userInfo.user_info.profile_img_url) {
      imgRef.current.src = REACT_APP_API_URL + userInfo.user_info.profile_img_url;
      setProfilePhoto(REACT_APP_API_URL + userInfo.user_info.profile_img_url);
    }
    if (userInfo && userInfo.user_info && userInfo.user_info.state) {
      setAddSelectedOption(JSON.parse(userInfo.user_info.state));
    }
  }, []);

  return (
    <div className="kitchen-padd">
      <Toaster />
      <div className="edit-profile mt-4">
        <form id="updatePersonalInformation" onSubmit={handleSubmit(updateUserProfile)}>
          <div className="personal-info">
            <div className="edit-picture align-items-center">
              <div className="picture">
                <img ref={imgRef} src={require("../../../Assets/images/user-avatar.png")} />

                {/* {userInfo && userInfo.image ? <img ref={imgRef} src={REACT_APP_API_PUBLIC_URL + userInfo.image} /> : 
              <img ref={imgRef} src={require("../../../Assets/images/user-avatar.png")} />} */}
              </div>
              <h3 style={{ fontSize: "24px" }}> {userInfo.name}</h3>
              {/* <div className="change-picture">
                <div className="edit-buttons d-flex">
                  <div className="changePicture position-relative" id="changePicture">
                    <input
                      className="form-control"
                      accept="image/*"
                      onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                          const file = e.target.files[0];
                          const src = URL.createObjectURL(file);
                          imgRef.src = src;
                          setProfilePhoto(file);
                        } else {
                          console.error("No file selected");
                        }
                      }}
                      type="file"
                      id="avabuider-box"
                      style={{ opacity: "0", zIndex: 1, position: "absolute", left: 0 }}
                    />
                    <input
                      className="form-control"
                      accept="image/*"
                      onChange={(e) => {
                        handleFileChange();
                      }}
                      type="file"
                      // value={""}
                      id="avabuider-box"
                      style={{ opacity: "0", zIndex: 1, position: "absolute", left: 0 }}
                    />
                    Change Picture
                  </div>
                  <div>
                    <button onClick={() => removeProfileImg()} type="button" className="removePicture" id="removePicture">
                      Remove Picture
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="data-form mt-3">
              <div className="form-row row mb-5">
                <div className="form-group col-lg-6 col-md-6">
                  <label for="updateName">Name</label>
                  <input type="text" className="form-control kitchen-input" id="updateName" placeholder="Mark Anderson" defaultValue={userInfo.name || ""} name="name" {...register("name", { required: "This field is required!" })} />
                  <p className="text-danger fs-6 mt-0">{errors.name ? "This field is required!" : ""}</p>
                </div>
              </div>
              <div className="form-row row mb-5">
                <div className="form-group col-lg-6 col-md-6">
                  <label for="updateEmail">Email</label>
                  <input type="email" className="form-control kitchen-input" id="updateEmail" placeholder="email@example.com" defaultValue={userInfo.email || ""} name="email" {...register("email", { required: "This field is required!" })} />
                  <p className="text-danger fs-6 mt-0">{errors.email ? "This field is required!" : ""}</p>
                </div>
              </div>
              <div className="form-row row mb-5">
                <div className="form-group col-lg-6 col-md-6">
                  <label>
                    Password <span>*</span>
                  </label>
                  <input
                    className="form-control kitchen-input"
                    type="password"
                    name="password"
                    placeholder="Enter your password"
                    {...register("password", {
                      minLength: {
                        value: 8,
                        message: "Password must have at least 8 characters",
                      },
                    })}
                  />
                  <p className="text-danger fs-6 text-start">{errors.password?.message}</p>
                  <span className="password-toggle-icon"></span>
                </div>
              </div>
              <div className="form-row row mb-5">
                <div className="form-group col-lg-6 col-md-6">
                  <label>
                    Confirm Password <span>*</span>
                  </label>
                  <input
                    className="form-control kitchen-input"
                    name="confirmPassword"
                    type="password"
                    placeholder="Enter your Confirm Password"
                    {...register("confirmPassword", {
                      validate: (value) =>
                        // Check if both fields are empty, if so, return true to avoid the mismatch error
                        value === password || (!value && !password) || "The passwords do not match",
                    })}
                  />
                  <p className="text-danger fs-6 text-start">{errors.confirmPassword?.message}</p>
                  <span className="password-toggle-icon"></span>
                </div>
              </div>
            </div>
          </div>
          <button type="submit" className="btn-custom btn-w">
            {isLoading ? <>Saving...</> : "Save Changes"}
          </button>
        </form>
      </div>
    </div>
  );
};
export default EditProfile;
