import React, { useEffect, useState } from "react";
import { getStorage, flushStorage } from "../../../../Utilities/storage";
import "../../Dashboard/Dashboard.scss";
import { apiGet, apiDelete } from "../../../../Utilities/userAuth";
import { apiResponse, handleError } from "../../../../Utilities/customHelper";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
const CreditPoints = () => {
  const [getRecipes, setGetRecipe] = useState([]);
  const [isLoading, setIsLoading] = useState();

  let userInfo = getStorage("userInfo");
  const navigate = useNavigate();
  const { REACT_APP_API_URL } = process.env;

  let percentage = 100;

  if (parseInt(getRecipes.in_points) > parseInt(getRecipes.out_points)) {
    percentage = (parseInt(getRecipes.out_points) / parseInt(getRecipes.in_points)) * 100;
    percentage = Math.round(percentage * 10) / 10;
  }
  useEffect(() => {
    getAllCredits();
  }, []);

  let getAllCredits = () => {
    // setIsLoading(true);

    if (userInfo && userInfo.token) {
      let url = `${REACT_APP_API_URL}customer/points/`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: userInfo.token,
      };
      const params = {};
      apiGet(url, params, true)
        .then((response) => {
          if (response.data.success === true) {
            let valuesFromDb = response.data.payload;
            setGetRecipe(valuesFromDb);
            setIsLoading(false);
          } else {
            let valuesFromDb = {
              in_points: "10000",
              out_points: "50",
              remaining_points: "9950",
            };
            setGetRecipe(valuesFromDb);

            // apiResponse(response, navigate);
          }
        })
        .catch((error) => {
          handleError();
        });
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="credit-points">
      <h5>Available Credits</h5>
      <div className="row">
        <div className="col-lg-8 col-md-6 col-sm-12">
          <div className="">
            <p>Keep track of your recipe balance and stay inspired!</p>
            <h5 className="base-heading" style={{ marginTop: "16px" }}>
              Credit Points
            </h5>
            <div className="points d-flex">
              <div className="total-credit" style={{ paddingRight: "16px" }}>
                <p>Total </p>
                <p>
                  <b> {getRecipes && getRecipes.in_points ? getRecipes.in_points : 0} </b>
                </p>
              </div>

              <div className="total-credit" style={{ paddingLeft: "8px", paddingRight: "8px", borderLeftWidth: "0px" }}>
                <p>Used</p>
                <p>
                  <b>{getRecipes && getRecipes.out_points ? getRecipes.out_points : 0}</b>
                </p>
              </div>
              <div className="total-credit" style={{ paddingLeft: "8px", paddingRight: "8px", borderRight: "0px" }}>
                <p>Remaining</p>
                <p>
                  <b>{getRecipes && getRecipes.remaining_points ? getRecipes.remaining_points : 0}</b>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="points-percentage">
            <div style={{ width: 130, height: 130, position: "relative" }}>
              <CircularProgressbar
                value={percentage}
                text={`${percentage}%`}
                styles={buildStyles({
                  textSize: "16px",
                  pathTransitionDuration: 0.5,
                  textColor: "#111012",
                  pathColor: "#E1BB78",
                  trailColor: "#d6d6d6",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: "600",
                })}
              />
              <div style={{ position: "absolute", top: "65%", left: "50%", transform: "translate(-50%, -50%)", fontSize: "15px", color: "#C0C0C0", fontFamily: "Poppins" }}>Used</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreditPoints;
