import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { apiGet, apiDelete, apiGetAll } from "../../../../Utilities/userAuth";
import { ReactSVG } from "react-svg";
import logo from "../../../../Assets/images/GenerateRecipe/logo.svg";
import angle from "../../../../Assets/images/GenerateRecipe/angle.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from "react-share";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDF from "./PDF";
import { apiResponse, handleError } from "../../../../Utilities/customHelper";

import "./Search.scss";
import { apiPut } from "../../../../Utilities/userAuth";
import toast, { Toaster } from "react-hot-toast";

const PublicRecipe = ({ recipe, show, setShow, formattedText, plainText }) => {
  const location = useLocation();

  const { REACT_APP_API_URL } = process.env;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { pathname } = useLocation();

  const [innerPage, setInnerPage] = useState(false);
  const countString = (str, letter) => {
    const re = new RegExp(letter, "g");

    const count = str.match(re).length;

    return count;
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    let letterToCheck = "/";

    const result = countString(pathname, letterToCheck);
    window.addEventListener("resize", handleWindowResize);
    if (pathname.includes("company")) {
      setInnerPage(false);
    } else {
      if (result > 1) {
        setInnerPage(true);
      } else {
        setInnerPage(false);
      }
    }
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const iconStyle = {
    borderRadius: "100%",
    padding: "5px 9px",
    marginRight: "10px",
  };
  const downloadStyle = {
    ...iconStyle,
    color: "#000000",
    border: "1px solid #000000",
  };
  // const shareUrl = queryParamsStateBack ? queryParamsStateBack : queryParams;
  const title = "Check this out!";
  const history = useNavigate();

  const navigate = useNavigate();
  const [getData, setGetData] = useState([]);
  const get_params = useParams();
  let getId = get_params.id;
  getId = getId.split("~");
  const id = parseInt(getId, 10);

  console.log(id);

  const fetchData = () => {
    console.log("getId==", getId);
    if (getId) {
      let url = `${REACT_APP_API_URL}recipe/${id}`;
      const params = {
        id: getId[1],
      };
      apiGetAll(url, params)
        .then((response) => {
          console.log(response.data.payload);
          if (response.status === 200) {
            let valuesFromDb = response.data.payload;
            setGetData(valuesFromDb);
          } else {
            apiResponse(response, navigate);
          }
        })
        .catch((error) => {
          handleError();
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="bdy recipe">
        <header>
          <div className="container">
            <Toaster />
            <div className="row">
              <div className="col-sm-12">
                <div className={windowWidth > 768 ? "d-flex justify-content-between align-items-center" : "d-block"}>
                  <div className="logo">
                    <ReactSVG src={logo} />
                  </div>
                  <div className="custom-btns d-flex">
                    <div>
                      <Link to={"/login"} type={"button"} className="btn-b d-flex btn-custom-white  ">
                        <text> Login</text>
                      </Link>
                    </div>

                    <div>
                      <Link to={"/signup"} type={"button"} className="btn-b d-flex btn-custom-white  ">
                        <text> Signup</text>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="header-content">
                  <div className="d-flex justify-content-between">
                    <div>
                      <Link
                        to={"/generate-recipe"}
                        type={"button"}
                        onClick={() => {
                          setShow(true);
                          // clearQueryParams();
                          // updateUrl();
                        }}
                        className="btn-b d-flex"
                      >
                        <ReactSVG src={angle} /> &nbsp;
                        <text>Back</text>
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <h1>Here’s Your Recipe:</h1>
                    <div className="d-flex">
                      {/* <div className="me-2">
                        <TwitterShareButton className="Demo__some-network__share-button">
                          <TwitterIcon size={35} round />
                        </TwitterShareButton>
                      </div> 
                        <div className="me-2">
                       <FacebookShareButton url={window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + location.pathname + "?" + shareUrl} quote={title} className="Demo__some-network__share-button">
                            <FacebookIcon size={35} round />
                          </FacebookShareButton> 
                      </div>*/}

                      <PDFDownloadLink document={<PDF formattedText={formattedText} plainText={getData && getData.recipe} />} fileName="Recipe.pdf">
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            ""
                          ) : (
                            <div style={downloadStyle} className="align-self-start">
                              <FontAwesomeIcon icon={faDownload} size="1x" />
                            </div>
                          )
                        }
                      </PDFDownloadLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="recipe-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="content-holder">
                  <div className="mb-3">
                    <h3 className="mb-1">Ingredients</h3>
                    {getData && getData.ingredients}
                  </div>
                  <div className="mb-3">
                    <h3 className="mb-1">Spices</h3>
                    {getData && getData.spices}
                  </div>
                  <div className="mb-3">
                    <h3>Instructions:</h3>
                    <p style={{ fontSize: "16px", paddingBottom: "16px" }}>{getData && getData.name}</p>
                    <div style={{ fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: getData && getData.recipe }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicRecipe;
