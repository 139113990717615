import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
import "../../custom-styles.scss";
import dashboardActive from "../../Assets/svgs/sidebar/dashboard-active.svg";
import GoalActive from "../../Assets/svgs/sidebar/goal-active.svg";
import exerciseActive from "../../Assets/svgs/sidebar/exercise-active.svg";
import settings from "../../Assets/svgs/sidebar/setting-2.svg";
import logo from "../../Assets/svgs/logo.svg";
import Logout from "../../Assets/svgs/sidebar/login.svg";
import { apiPost } from "../../Utilities/userAuth";
import { getStorage, flushStorage } from "../../Utilities/storage";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router";
import Logo from "../Logo/Logo";
import paymentActive from "../../Assets/svgs/sidebar/payment-active.svg";
import RecipeActive from "../../Assets/svgs/sidebar/recipe-active.svg";
import Recipe from "../../Assets/svgs/sidebar/recipe.svg";
import payment from "../../Assets/svgs/sidebar/payment.svg";
import Star from "../../Assets/svgs/sidebar/star.svg";
import StarActive from "../../Assets/svgs/sidebar/star-active.svg";
import { FaRegUser, FaUser } from "react-icons/fa";

const Sidebar = ({ close, setClose }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  let userInfo = getStorage("userInfo");
  const { REACT_APP_API_URL } = process.env;

  let LogoutFun = () => {
    setIsLoading(true);
    if (userInfo) {
      let url = `${REACT_APP_API_URL}/user/logout`;
      const data = {
        token: userInfo.token,
        user_id: userInfo && userInfo.user_info && userInfo.user_info.id,
      };
      apiPost(url, data)
        .then((response) => {
          setTimeout(() => {
            setIsLoading(false);
            localStorage.removeItem("userInfo");
            localStorage.removeItem("token");
            navigate("/login");
          }, 1000);
        })
        .catch((error) => {
          setTimeout(() => {
            setIsLoading(false);
            localStorage.removeItem("userInfo");
            localStorage.removeItem("token");
            flushStorage();
            navigate("/login");
          }, 3000);
        });
    } else {
      localStorage.removeItem("userInfo");
      localStorage.removeItem("token");
      flushStorage();
      navigate("/login");
    }
  };

  const sidebarStyles = {
    position: close ? "absolute" : "fixed",
    backgroundColor: close ? "#B32D09" : "initial",
    maxWidth: "70%",
    width: close ? "100%" : "100%",
    zIndex: 999,
    height: "100vh",
    // position: "fixed",
  };
  if (window.innerWidth <= 768) {
    sidebarStyles.width = "100%";
  }
  if (window.innerWidth > 768) {
    sidebarStyles.maxWidth = "50%";
  }

  return (
    <>
      {close ? <div className={`backdrop ${close ? "d-block" : ""}`}></div> : ""}
      <div className={`dashboard-sidebar ${close ? "d-block" : ""}`} style={sidebarStyles}>
        <div className="close-sidebar" onClick={() => setClose(!close)}>
          X
        </div>

        <div className="sidebar-profile position-relative">
          <NavLink to="/generate-recipe" className="text-center mb-5 mt-3">
            <Logo />
          </NavLink>
        </div>
        <div className="sidebar-menu">
          <NavLink to={"/"} className={({ isActive }) => (isActive ? "active list-btn" : "list-btn")}>
            <span>
              <ReactSVG src={dashboardActive} size={17} />
            </span>
            Dashbaord
          </NavLink>

          <NavLink to={"/recipe"} className={({ isActive }) => (isActive ? "active list-btn" : "list-btn")}>
            <span>
              <ReactSVG src={RecipeActive} size={17} />
            </span>
            Saved
          </NavLink>
          <NavLink to={"/credits"} className={({ isActive }) => (isActive ? "active list-btn" : "list-btn")}>
            <span>
              <ReactSVG src={StarActive} size={17} />
            </span>
            Credits
          </NavLink>
          <NavLink to={"/payments"} className={({ isActive }) => (isActive ? "active list-btn" : "list-btn")}>
            <span>
              {/* <img src={require("../../assets/img/dashboard/icons/courses.png")} /> */}
              <ReactSVG src={paymentActive} size={117} />
            </span>
            Payments
          </NavLink>
          <NavLink to={"/edit-profile"} className={({ isActive }) => (isActive ? "active list-btn" : "list-btn")}>
            <span>
              {/* <img src={require("../../assets/img/dashboard/icons/courses.png")} /> */}
              <FaUser color="#b32d09" />
            </span>
            Edit Profile
          </NavLink>

          <button type="button" onClick={() => LogoutFun()} className=" d-flex mt-3 bg-white text-dark btn btn-custom mt-0">
            {isLoading ? (
              <Toaster />
            ) : (
              <>
                <ReactSVG src={Logout} />
                &nbsp; Logout
              </>
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
