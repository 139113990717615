import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./Sidebar.css";
import { ReactSVG } from "react-svg";
import { IoSettingsOutline } from "react-icons/io5";
import { FaRegUser, FaUser } from "react-icons/fa";

import { useNavigate } from "react-router";
import Dashboard from "../../Assets/svgs/sidebar/dashboard.svg";
import DashboardActive from "../../Assets/svgs/sidebar/dashboard-active.svg";
import paymentActive from "../../Assets/svgs/sidebar/payment-active.svg";
import Star from "../../Assets/svgs/sidebar/star.svg";
import StarActive from "../../Assets/svgs/sidebar/star-active.svg";
import RecipeActive from "../../Assets/svgs/sidebar/recipe-active.svg";
import Recipe from "../../Assets/svgs/sidebar/recipe.svg";
import payment from "../../Assets/svgs/sidebar/payment.svg";
import Logout from "../../Assets/svgs/logout.svg";
import { apiPost } from "../../Utilities/userAuth";
import { getStorage, flushStorage } from "../../Utilities/storage";
import { Toaster } from "react-hot-toast";
import Logo from "../Logo/Logo";
import { IoMdSettings } from "react-icons/io";

const Sidebar = () => {
  const [isLoading, setIsLoading] = useState();
  const location = useLocation();
  const userInfo = getStorage("userInfo");

  const navigate = useNavigate();

  const LogoutFun = () => {
    setIsLoading(true);

    clearLocalStorageAndNavigate();
  };

  const clearLocalStorageAndNavigate = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("token");

    setIsLoading(false);

    navigate("/login");
  };

  return (
    <div className="dashboard-sidebar">
      <div className="sidebar">
        <div className="d-flex flex-column flex-shrink-0 sidebar-1 text-white ">
          <NavLink to="/generate-recipe" className="text-center mb-5 mt-3">
            <div className="sidebar-logo">
              <Logo />
            </div>
          </NavLink>
          <ul className="nav nav-pills flex-column mb-auto">
            {userInfo && userInfo.role === "ADMIN" ? (
              <li className="nav-item">
                <NavLink to="/users" className={location.pathname === "/users" || location.pathname === "/" ? "nav-link active" : "nav-link"}>
                  <div className="align-self-center">{location.pathname === "/users" || location.pathname === "/" ? <ReactSVG src={DashboardActive} /> : <ReactSVG src={Dashboard} />}</div>
                  <div className="align-self-center">&nbsp; Users</div>
                </NavLink>
              </li>
            ) : (
              <li className="nav-item">
                <NavLink to="/" className={location.pathname === "/dashboard" || location.pathname === "/" ? "nav-link active" : "nav-link"}>
                  <div className="align-self-center">{location.pathname === "/dashboard" || location.pathname === "/" ? <ReactSVG src={DashboardActive} /> : <ReactSVG src={Dashboard} />}</div>
                  <div className="align-self-center">&nbsp; Dashboard</div>
                </NavLink>
              </li>
            )}

            {userInfo && userInfo.role === "ADMIN" ? (
              ""
            ) : (
              <>
                <li className="nav-item">
                  <NavLink to="/recipe" className={location.pathname === "/recipe" || location.pathname === "/all-recipes" ? "nav-link active" : "nav-link"}>
                    <div className="align-self-center">{location.pathname === "/recipe" || location.pathname === "/all-recipes" ? <ReactSVG src={RecipeActive} /> : <ReactSVG src={Recipe} />}</div>
                    <div className="align-self-center">&nbsp; Saved</div>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/credits" className={location.pathname === "/credits" ? "nav-link active" : "nav-link  d-flex"}>
                    <div className="align-self-center">{location.pathname === "/credits" ? <ReactSVG src={StarActive} /> : <ReactSVG src={Star} />}</div>
                    <div className="align-self-center">&nbsp; Credits</div>
                  </NavLink>
                </li>
              </>
            )}

            <li className="nav-item">
              <NavLink to="/payments" className={location.pathname === "/payments" ? "nav-link active" : "nav-link  d-flex"}>
                <div className="align-self-center">{location.pathname === "/payments" ? <ReactSVG src={paymentActive} /> : <ReactSVG src={payment} />}</div>
                <div className="align-self-center">&nbsp; Payments</div>
              </NavLink>
            </li>
            {userInfo && userInfo.role === "ADMIN" ? (
              <li className="nav-item">
                <NavLink to="/settings" className={location.pathname === "/settings" ? "nav-link active" : "nav-link"}>
                  <div className="align-self-center">{location.pathname === "/settings" ? <IoMdSettings color="#b32d09" /> : <IoSettingsOutline />}</div>
                  <div className="align-self-center">&nbsp; Settings</div>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            <li className="nav-item">
              <NavLink to="/edit-profile" className={location.pathname === "/edit-profile" ? "nav-link active" : "nav-link  d-flex"}>
                <div className="align-self-center">{location.pathname === "/edit-profile" ? <FaUser color="#b32d09" /> : <FaRegUser />}</div>
                <div className="align-self-center">&nbsp; Edit Profile</div>
              </NavLink>
            </li>
          </ul>
          <hr />
          <div className="dropdown-logout">
            <button type="button" className="btn btn-custom mt-0 logout-btn" onClick={LogoutFun}>
              {isLoading ? (
                <Toaster />
              ) : (
                <>
                  <ReactSVG src={Logout} />
                  <div className="align-self-center">&nbsp; Logout</div>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
