import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { ReactSVG } from "react-svg";
import logo from "../../../../Assets/images/GenerateRecipe/logo.svg";
import angle from "../../../../Assets/images/GenerateRecipe/angle.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from "react-share";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDF from "./PDF";
import "./Search.scss";
import { apiPut } from "../../../../Utilities/userAuth";
import toast, { Toaster } from "react-hot-toast";

const Search = ({ recipe, show, setShow, formattedText, plainText, recipeId, setIsLoading }) => {
  const location = useLocation();

  const { REACT_APP_API_URL } = process.env;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { pathname } = useLocation();

  const [innerPage, setInnerPage] = useState(false);
  const countString = (str, letter) => {
    const re = new RegExp(letter, "g");

    const count = str.match(re).length;

    return count;
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    let letterToCheck = "/";

    const result = countString(pathname, letterToCheck);
    window.addEventListener("resize", handleWindowResize);
    if (pathname.includes("company")) {
      setInnerPage(false);
    } else {
      if (result > 1) {
        setInnerPage(true);
      } else {
        setInnerPage(false);
      }
    }
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  // const savetheRecipe = (id) => {
  //   let url = `${REACT_APP_API_URL}recipe/${id}`;
  //   const params = {
  //     type: 1,
  //   };
  //   const headers = {
  //     "Content-Type": "multipart/form-data",
  //   };
  //   apiPut(url, params, headers)
  //     .then((response) => {
  //       if (response.data.success === true) {
  //         toast.success(response.data.message);
  //       } else {
  //         if (response.status === 400 || response.status === 401) {
  //           toast.error(response.data.message);
  //         } else if (response.status === 422) {
  //           let errors = response.data;
  //           for (let key in errors) {
  //             toast.error(errors[key]);
  //           }
  //         } else {
  //           let msg = "Response Error! Please try again later.";
  //           toast.error(msg);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       let msg = "Something Wrong! Please try after some time.";
  //       toast.error(msg);
  //     });
  // };
  const iconStyle = {
    borderRadius: "100%",
    padding: "5px 9px",
    marginRight: "10px",
  };
  const downloadStyle = {
    ...iconStyle,
    color: "#000000",
    border: "1px solid #000000",
  };
  // const shareUrl = queryParamsStateBack ? queryParamsStateBack : queryParams;
  const title = "Check this out!";
  const history = useNavigate();

  const dynamicUrl = `${window.location.origin}/recipe-all/${recipeId}`;

  // Function to format the ingredients and instructions
  const formatRecipeContent = (content) => {
    const ingredientsStart = content.indexOf("Ingredients:");
    const instructionsStart = content.indexOf("Instructions:");

    if (ingredientsStart === -1 || instructionsStart === -1) {
      return content; // return original content if format is not as expected
    }

    const ingredientsContent = content.substring(ingredientsStart + 12, instructionsStart).trim();
    const instructionsContent = content.substring(instructionsStart + 13).trim();

    const ingredientsList = ingredientsContent
      .split("\n")
      .map((ingredient) => ingredient.trim())
      .filter((ingredient) => ingredient)
      .map((ingredient, index) => `<div key=${index}>${ingredient}</div>`)
      .join("");

    const formattedIngredients = `<h4>Ingredients:</h4><div>${ingredientsList}</div>`;

    // Remove existing numbering from instructions and format as list items
    const instructionsList = instructionsContent
      .split("\n")
      .map((step) => step.replace(/^\d+\.\s*/, "").trim()) // Remove existing numbering
      .filter((step) => step)
      .map((step, index) => `<li key=${index}>${step}</li>`)
      .join("");

    const formattedInstructions = `<h4>Instructions:</h4><ol>${instructionsList}</ol>`;

    return `${formattedIngredients}${formattedInstructions}`;
  };

  const formattedRecipeContent = recipe && recipe.recipe ? formatRecipeContent(recipe.recipe) : "";

  return (
    <>
      {show === false ? (
        <div className="bdy recipe">
          <header>
            <div className="container">
              <Toaster />
              <div className="row">
                <div className="col-sm-12">
                  <div className={windowWidth > 768 ? "d-flex justify-content-between align-items-center" : "d-block"}>
                    <div className="logo">
                      <ReactSVG src={logo} />
                    </div>
                    <div className="custom-btns d-flex">
                      {/* <div>
                        <Link
                          to={"/generate-recipe"}
                          type={"button"}
                          onClick={() => {
                            // setShow(true);
                            // clearQueryParams();
                            savetheRecipe(recipe.id);
                          }}
                          className="btn-b d-flex btn-custom-white  "
                        >
                          <text> Save Recipe</text>
                        </Link>
                      </div> */}
                      <div style={{ marginLeft: "12px", marginRight: "12px" }}>
                        <Link
                          type={"button"}
                          onClick={() => {
                            window.location.reload();
                          }}
                          className="btn-b d-flex btn-custom-white  "
                        >
                          <text>+ New Recipe</text>
                        </Link>
                      </div>
                      <div>
                        <Link to={"/"} type={"button"} className="btn-b d-flex btn-custom-white  ">
                          <text> Dashboard</text>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="header-content">
                    <div className="d-flex justify-content-between">
                      <div>
                        <Link
                          to={"/generate-recipe"}
                          type={"button"}
                          onClick={() => {
                            setShow(true);
                            // clearQueryParams();
                            // updateUrl();
                          }}
                          className="btn-b d-flex"
                        >
                          <ReactSVG src={angle} /> &nbsp;
                          <text>Back</text>
                        </Link>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <h1>Here’s Your Recipe:</h1>
                      <div className="d-flex">
                        <div className="me-2">
                          <TwitterShareButton url={dynamicUrl} className="Demo__some-network__share-button">
                            <TwitterIcon size={35} round />
                          </TwitterShareButton>
                        </div>
                        <div className="me-2">
                          {/* <FacebookShareButton
                            // url={window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + location.pathname + "?" + shareUrl} quote={title}
                            className="Demo__some-network__share-button"
                          >
                            <FacebookIcon size={35} round />
                          </FacebookShareButton> */}
                          <FacebookShareButton url={dynamicUrl} className="Demo__some-network__share-button">
                            <FacebookIcon size={35} round />
                          </FacebookShareButton>
                        </div>

                        <PDFDownloadLink document={<PDF formattedText={formattedText} plainText={plainText} />} fileName="Recipe.pdf">
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              ""
                            ) : (
                              <div style={downloadStyle} className="align-self-start">
                                <FontAwesomeIcon icon={faDownload} size="1x" />
                              </div>
                            )
                          }
                        </PDFDownloadLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <div className="recipe-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="content-holder">
                    <div className="mb-3">
                      <h3 className="mb-1">Recipe</h3>
                      {recipe && recipe.ingredients}
                    </div>
                    <div className="mb-3">
                      <h3 className="mb-1">Spices</h3>
                      {recipe && recipe.spices}
                    </div>
                    {/* <div className="mb-3">
                      <h3>Instructions:</h3>
                      <p style={{ fontSize: "16px", paddingBottom: "16px" }}>{recipe && recipe.name}</p>
                      <div style={{ fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: recipe && recipe.recipe }}></div>
                    </div> */}
                    <div className="mb-3">
                      <div style={{ fontSize: "14px" }} dangerouslySetInnerHTML={{ __html: formattedRecipeContent }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Search;
