import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import logo from "../../../../Assets/images/GenerateRecipe/logo.svg";

const Generating = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     navigate("/search");
  //   }, 6000);

  //   return () => clearTimeout(timer);
  // }, [navigate]);
  return (
    <div className="G-bdy">
      <header>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="logo text-center">
                <ReactSVG src={logo} />
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="loading">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="desire-meal">
                <h1 className="text-center">Generating Your Desired Meal!</h1>
                <div className="progress-container ">
                  <div className="progress-bar"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Generating;
