import React, { useState, useEffect } from "react";
import "../Payments.scss";
import { useForm } from "react-hook-form";
import { ReactSVG } from "react-svg";
import visa from "../../../../Assets/images/cards/visa.png";
import masterCard from "../../../../Assets/images/cards/master-card.png";
import mastero from "../../../../Assets/images/cards/mastero.png";
import jcbCard from "../../../../Assets/images/cards/jcb.png";
import unionPay from "../../../../Assets/images/cards/union-pay.png";
import discover from "../../../../Assets/images/cards/discover.png";
import number from "../../../../Assets/images/cards/number.png";
import info from "../../../../Assets/svgs/Info.svg";
import { apiPost } from "../../../../Utilities/userAuth";
import toast, { Toaster } from "react-hot-toast";
import Spinner from "../../../../Shared/Loaders/Spinner";

const CardDetailsForm = ({ getAllPayments }) => {
  const [stripe, setStripe] = useState(null);
  const [cardElement, setCardElement] = useState(null);
  const [isloading, setIsLoading] = useState();
  const { REACT_APP_API_URL } = process.env;

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/";
    script.onload = () => {
      const stripeInstance = window.Stripe("pk_test_51PDOAgHMOtdbhm5vAXLCvocy6foWZ8zevHaTt3hwk5KbbLhm1rqb6M0mY7ftqpkfMgstXPvPZkXm4ikAjjmTRXpY00puCmjzpg");
      setStripe(stripeInstance);
      const elements = stripeInstance.elements();
      const card = elements.create("card");
      card.mount("#card-element");
      setCardElement(card);
    };
    document.body.appendChild(script);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !cardElement) {
      return;
    }

    const { token, error } = await stripe.createToken(cardElement);
    if (error) {
      console.error("Error creating token:", error);
    } else {
      const amount = document.getElementById("amount").value;
      StripePayment(token.id, amount);
    }
  };

  const StripePayment = (token, amount) => {
    setIsLoading(true);
    let url = `${REACT_APP_API_URL}payment`;
    const params = {
      token: token,
      amount: amount,
    };
    apiPost(url, params, true)
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.message);
          document.getElementById("amount").value = "";
          toast.success(response.data.message);
          setIsLoading(false);
          getAllPayments();
          if (cardElement) {
            cardElement.clear();
          }
        } else {
          if (response.data.success === false) {
            toast.error("response.data.message", response.data.message);
          }
          if (response.status === 404) {
            console.log("2nd");
            toast.error(response.data.message);
          }
          if (parseInt(response.status) === 401) {
            toast.error(response.data.message);
          }
          if (parseInt(response.status) === 400) {
            toast.error(response.data.message);
          }

          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        let msg = "Something is wrong, please try after some time.";
        toast.error(msg);
        console.error("Error during API call:", error);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} method="post" id="payment-form">
        <Toaster />
        <div className="row">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="row">
              <div className="col-lg-12" style={{ position: "relative" }}>
                <div className="form-row">
                  <div id="card-element" className="form-control kitchen-input card-number"></div>

                  <div id="card-errors" role="alert"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12 pl-0">
            <input type="number" id="amount" name="amount" className="form-control kitchen-input" placeholder="1.00$" min="1" required />
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12 pl-0">
            <button type="submit" className="btn btn-custom mt-0">
              {isloading ? <Spinner /> : "+ Pay Now"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default CardDetailsForm;
