import React, { useState, useEffect } from "react";
import "../Dashboard.scss";
import { Link, useNavigate } from "react-router-dom";
import { apiGet } from "../../../../Utilities/userAuth";
import { apiResponse, handleError } from "../../../../Utilities/customHelper";
import { getStorage, flushStorage } from "../../../../Utilities/storage";
import SavedRecipesLoader from "../../../../Shared/Loaders/SavedRecipesLoader";

const SavedRecipies = () => {
  let userInfo = getStorage("userInfo");
  const navigate = useNavigate();
  const { REACT_APP_API_URL } = process.env;
  const [showAll, setShowAll] = useState(false);
  const [getRecipes, setGetRecipe] = useState([]);
  const [isLoading, setIsLoading] = useState();

  function shortenText(text, maxWords) {
    let words = text.split(" ");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(" ") + "...";
    }
    return text;
  }

  //remove extra characters like - \
  const cleanText = (text) => {
    return text.replace(/^\s*-\s*\\?"|"/g, "");
  };

  useEffect(() => {
    getAllRecipe();
  }, []);

  let getAllRecipe = () => {
    setIsLoading(true);
    if (userInfo && userInfo.token) {
      let url = `${REACT_APP_API_URL}recipe`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: userInfo.token,
      };
      const params = {};
      apiGet(url, params, true)
        .then((response) => {
          if (response.data.success === true) {
            let valuesFromDb = response.data.payload.records;
            setGetRecipe(valuesFromDb);
            setIsLoading(false);
          } else {
            if (response.data.success === false) {
              setIsLoading(false);
              console.log(response.data.message);
            }
            apiResponse(response, navigate);
          }
        })
        .catch((error) => {
          handleError();
        });
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <div className="saved-recipies ">
        <h1>Saved Recipies</h1>
        <div className="saved-recipies-inner tg-scroll-y">
          {isLoading ? (
            <SavedRecipesLoader />
          ) : getRecipes && getRecipes.length > 0 ? (
            getRecipes.map((item, idx) => (
              <div key={idx} className="recipie-one d-flex">
                <div className="index">
                  <h5 className="base-heading">{(idx + 1).toString().padStart(2, "0")}.</h5>
                </div>
                <div className="main-recipie">
                  <h5 className="base-heading">{item && item.name ? cleanText(item.name) : "Classic Gimlet Cocktail"}</h5>
                  <p>{item && item.recipe ? shortenText(cleanText(item.recipe), 13) : shortenText("We have aimed to show you everything that is relevant to you in one handy place...", 13)}</p>

                  <a href={`/recipe-detail/${item.id}`}>Read Now</a>
                </div>
                <hr />
              </div>
            ))
          ) : (
            "No Data Found"
          )}
        </div>
      </div>
    </>
  );
};
export default SavedRecipies;
