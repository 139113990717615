import React, { Suspense, useState } from "react";
import { getStorage } from "./Utilities/storage";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import "./custom-styles.scss";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider, NotificationsProvider } from "./Context/authContext";
import Login from "./Pages/Users/Auth/Login.jsx";
import SignUp from "./Pages/Users/Auth/SignUp.jsx";
import ForgotPassword from "./Pages/Users/Auth/ForgotPassword.jsx";
import PasswordSuccess from "./Pages/Users/Auth/PasswordSuccess.jsx";
import Dashboard from "./Pages/Users/Dashboard/Dashboard.jsx";
import Recipe from "./Pages/Users/Recipe/Recipe.jsx";
import Credits from "./Pages/Users/Credits/Credits.jsx";
import Payments from "./Pages/Users/Payments/Payments.jsx";
import Layout from "./Layout/Layout.jsx";
import AllRecipes from "./Pages/Users/Recipe/Components/AllRecipes.jsx";
import RecipeDetail from "./Pages/Users/Recipe/Components/RecipeDetail.jsx";
import GenerateRecipe from "./Pages/Users/GenerateRecipe/GenerateRecipe.jsx";
import Search from "./Pages/Users/GenerateRecipe/Components/Search.jsx";
import Users from "./Pages/Users/Users/Users.jsx";
import EditProfile from "./Pages/Users/EditProfile/EditProfile.jsx";
import Settings from "./Pages/Users/Settings/Settings.jsx";
import ResetPassword from "./Pages/Users/Auth/ResetPassword.jsx";
import PublicRecipe from "./Pages/Users/GenerateRecipe/Components/PublicRecipe.jsx";

export default function App() {
  let userRoutV = userRoutesFun();
  let ProtectedRoutes = userRoutV.props.userRoutes;
  const userInfo = getStorage("userInfo");
  const storageToken = localStorage.getItem("token");
  const [path, setPath] = useState();
  return (
    <>
      {storageToken !== null && storageToken !== "" ? (
        <Suspense
          fallback={
            <div style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <img src={require("./Assets/images/logo.png")} />
            </div>
          }
        >
          <BrowserRouter>
            <AuthProvider>
              <Routes>
                {/* Private Routes */}
                <Route element={<Layout />}>{ProtectedRoutes && ProtectedRoutes.map((item, i) => <Route key={i} name={item.name} path={item.path} element={item.desktopModule} />)}</Route>
                {/* Public Routes */}
                <Route exact={true} name="login" path="/login" element={<Login />}></Route>
                <Route exact={true} name="signup" path="/signup" element={<SignUp />}></Route>
                <Route exact={true} name="forgotPassword" path="/forgot-password" element={<ForgotPassword />}></Route>
                <Route exact={true} name="PasswordSuccess" path="/password-success" element={<PasswordSuccess />}></Route>
                <Route exact={true} name="ResetPassword" path="/reset-password" element={<ResetPassword />}></Route>
                <Route exact={true} name="GenerateRecipe" path="/generate-recipe" element={<GenerateRecipe />}></Route>
                <Route exact={true} name="PublicRecipe" path="/recipe-all/:id" element={<PublicRecipe />}></Route>
                <Route exact={true} name="search" path="/search" element={<Search />}></Route>

                <Route exact={true} path="/" element={<Navigate to="/generate-recipe" />}></Route>
              </Routes>
            </AuthProvider>
          </BrowserRouter>
        </Suspense>
      ) : (
        <Suspense
          fallback={
            <div style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <img src={require("./Assets/images/logo.png")} />
            </div>
          }
        >
          <BrowserRouter>
            <Routes>
              <Route exact={true} name="login" path="/login" element={<Login />}></Route>
              <Route exact={true} name="signup" path="/signup" element={<SignUp />}></Route>
              <Route exact={true} name="forgotPassword" path="/forgot-password" element={<ForgotPassword />}></Route>
              <Route exact={true} name="PasswordSuccess" path="/password-success" element={<PasswordSuccess />}></Route>
              <Route exact={true} name="ResetPassword" path="/reset-password" element={<ResetPassword />}></Route>
              <Route exact={true} name="GenerateRecipe" path="/generate-recipe" element={<GenerateRecipe />}></Route>
              <Route exact={true} name="PublicRecipe" path="/recipe-all/:id" element={<PublicRecipe />}></Route>
              <Route exact={true} name="search" path="/search" element={<Search />}></Route>
              {/* <Route exact={true} path="*" element={<Navigate to="/generate-recipe" />}></Route> */}

              <Route exact={true} path="*" element={<Navigate to="/generate-recipe" />}></Route>
            </Routes>
          </BrowserRouter>
        </Suspense>
      )}
    </>
  );
}

export function userRoutesFun() {
  const userInfo = getStorage("userInfo");
  let userRoutes = [
    {
      exact: true,
      name: "dashboard",
      path: "/",
      desktopModule: userInfo && userInfo.role === "ADMIN" ? <Users /> : userInfo && userInfo.role === "CUSTOMER" ? <Dashboard /> : "",
    },
    {
      exact: true,
      name: "recipe",
      path: "/recipe",
      desktopModule: <Recipe />,
    },
    {
      exact: true,
      name: "users",
      path: userInfo && userInfo.role === "ADMIN" ? "/users" : userInfo && userInfo.role === "CUSTOMER" ? "/dashboard" : "",
      desktopModule: <Users />,
    },
    {
      exact: true,
      name: "settings",
      path: userInfo && userInfo.role === "ADMIN" ? "/settings" : userInfo && userInfo.role === "CUSTOMER" ? "/dashboard" : "",
      desktopModule: <Settings />,
    },
    {
      exact: true,
      name: "EditProfile",
      path: "/edit-profile",
      desktopModule: <EditProfile />,
    },

    {
      exact: true,
      name: "Allrecipe",
      path: "/all-recipes",
      desktopModule: <AllRecipes />,
    },
    {
      exact: true,
      name: "recipeDetails",
      path: "/recipe-detail/:id",
      desktopModule: <RecipeDetail />,
    },
    {
      exact: true,
      name: "credits",
      path: "/credits",
      desktopModule: <Credits />,
    },
    {
      exact: true,
      name: "payments",
      path: "/payments",
      desktopModule: <Payments />,
    },
    {
      exact: true,
      name: "generate-recipe",
      path: "/",
      desktopModule: <GenerateRecipe />,
    },
  ];

  return {
    props: {
      userRoutes,
    },
  };
}
