import React, { useState, useEffect } from "react";
import "./Auth.scss";
import "../../../custom-styles.scss";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { apiPost } from "../../../../src/Utilities/userAuth";
import toast, { Toaster } from "react-hot-toast";
import { setStorage, getStorage, flushStorage } from "../../../../src/Utilities/storage";
import Logo from "../../../Shared/Logo/Logo";
import Spinner from "../../../Shared/Loaders/Spinner";

function SignUp() {
  const { REACT_APP_API_URL } = process.env;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [priLoading, setPrivLoading] = useState();
  const [newCreatedId, setNewCreatedId] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      // email: "",
      // password: "",
    },
  });
  const password = watch("password");

  const createAccount = (formData) => {
    setIsLoading(true);
    if (newCreatedId === "") {
      let url = `${REACT_APP_API_URL}auth/register`;
      const params = {
        name: formData.name,
        email: formData.email,
        password: formData.password,
      };

      apiPost(url, params)
        .then((response) => {
          if (response.data.success === true) {
            setIsLoading(false);
            toast.success(response.data.message);
            setNewCreatedId(response.data.payload.id);
            setTimeout(() => {
              navigate("/login");
            }, 1000);
          } else {
            if (response.status === 422) {
              const errorMessage = response.data.errors[0].message;
              toast.error(errorMessage);
              setIsLoading(false);
            } else if (response.data.message) {
              setIsLoading(false);

              toast.error(response.data.message);
            } else {
              setIsLoading(false);

              let msg = "Response Error! Please try again later.";
              toast.error(msg);
            }
          }
        })
        .catch((error) => {
          let msg = "Something Wrong! Please try after some time.";
          toast.error(msg);
        })
        .finally(() => {
          setPrivLoading(false);
        });
    }
  };

  return (
    <div className="auth-login">
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <div className="login-main">
          <div className="d-flex justify-content-center ">
            <Toaster />
          </div>
          <h2 className="card-title text-center mb-3">
            <Logo />
          </h2>
          <p className="card-text text-center mb-4">Create your Account</p>
          <form onSubmit={handleSubmit(createAccount)}>
            <div className="mb-3 position-relative">
              <label>
                Full Name <span>*</span>
              </label>
              <input type="name" className="form-control kitchen-input" name="name" {...register("name", { required: "This field is required" })} placeholder="Enter your Full Name" />
              <p className="text-danger fs-6 text-start">{errors.name?.message}</p>
            </div>
            <div className="mb-3 position-relative">
              <label>
                Email <span>*</span>
              </label>
              <input type="email" className="form-control kitchen-input" name="email" {...register("email", { required: "This field is required" })} placeholder="Enter your email address" />
              <p className="text-danger fs-6 text-start">{errors.email?.message}</p>
            </div>
            {/* <div className="mb-3 position-relative">
              <label>
                Password <span>*</span>
              </label>
              <input
                className="form-control kitchen-input"
                type="password"
                name="password"
                placeholder="Enter your password"
                {...register("password", {
                  required: "This field is required!",
                  minLength: {
                    value: 8,
                    message: "Password must have at least 8 characters",
                  },
                })}
              />
              <p className="text-danger fs-6 text-start">{errors.password?.message}</p>
              <span className="password-toggle-icon"></span>
            </div>

            <div className="mb-3 position-relative">
              <label>
                Confirm Password <span>*</span>
              </label>
              <input
                className="form-control kitchen-input"
                name="confirmPassword"
                type="password"
                placeholder="Enter your Confirm Password"
                {...register("confirmPassword", {
                  validate: (value) => value === password || "The passwords do not match",
                })}
              />
              <p className="text-danger fs-6 text-start">{errors.confirmPassword?.message}</p>
              <span className="password-toggle-icon"></span>
            </div> */}
            <div className="mb-3 position-relative">
              <label>
                Password <span>*</span>
              </label>
              <input
                className="form-control kitchen-input"
                type="password"
                name="password"
                placeholder="Enter your password"
                {...register("password", {
                  required: "This field is required!",
                  pattern: {
                    value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$_()*&#%;?]).{8,32}/,
                    message: "Password must be atleast 8 characters, including 1 uppercase, 1 lowercase, 1 number, and 1 special character (!@#$_()*&#%;?).",
                  },
                })}
              />

              <p className="text-danger fs-6 text-start">{errors.password?.message}</p>
              <span className="password-toggle-icon"></span>
            </div>

            <div className="mb-3 position-relative">
              <label>
                Confirm Password <span>*</span>
              </label>
              <input
                className="form-control kitchen-input"
                name="confirmPassword"
                type="password"
                placeholder="Enter your Confirm Password"
                {...register("confirmPassword", {
                  validate: (value) => value === password || "The passwords do not match",
                })}
              />
              <p className="text-danger fs-6 text-start">{errors.confirmPassword?.message}</p>
              <span className="password-toggle-icon"></span>
            </div>

            <div className="d-grid mb-2">
              <div className="login-btn">
                <button type="submit" className="btn btn-primary btn-lg btn-custom">
                  {isLoading ? <Spinner /> : "Sign up"}
                </button>
              </div>
            </div>
            <div className="d-grid create-account">
              <p>
                Already have an Account ?{" "}
                <Link to={"/login"}>
                  <span className="text-primary"> Login</span>
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
