import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "../Dashboard/Dashboard.scss";
import { getStorage, flushStorage } from "../../../Utilities/storage";
import SavedRecipies from "../Dashboard/Components/SavedRecipies";
import "react-circular-progressbar/dist/styles.css";
import CreditPoints from "./Components/CreditPoints";

const Credits = () => {
  return (
    <div className="kitchen-padd">
      <div className="dashboard-container">
        <div className="row">
          <div className="col-lg-8">
            <CreditPoints />
            {/* How to use Points */}

            <div className="credit-points how-to-use">
              <h5>How to Use Credits</h5>
              <div className="row">
                <div className="">
                  <p>Unlock the full potential of your recipe credits with these simple steps:</p>
                  <div className="check-balance">
                    <h5>Check Your Balance</h5>
                    <p>Stay updated on your available recipe credits.</p>
                  </div>
                  <div className="check-balance">
                    <h5>Redeem Wisely</h5>
                    <p>Use your credits to create and save delicious recipes.</p>
                  </div>
                  <div className="check-balance">
                    <h5>Stay Informed</h5>
                    <p>Look out for special promotions, bonus credits, and limited-time offers.</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Want More Section */}
            <div className="want-more">
              <h5>Need More Recipe Credits?</h5>
              <p>Get more cooking inspiration! Click "Buy Now" to purchase additional recipe credits and continue creating delicious dishes</p>
              <Link to={"/payments"}>
                <button type="button" style={{ marginTop: "22px" }}>
                  Buy Now
                </button>
              </Link>
            </div>
          </div>
          <div className="col-lg-4">
            <SavedRecipies />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Credits;
