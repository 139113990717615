import React, { useEffect, useState } from "react";
import "./Recipe.scss";
import { ReactSVG } from "react-svg";
import { Link, NavLink, useNavigate } from "react-router-dom";

import Fork from "../../../Assets/svgs/fork.svg";
import remove from "../../../Assets/svgs/remove.svg";
import maximise from "../../../Assets/svgs/maximize.svg";
import back from "../../../Assets/svgs/back.svg";
import used from "../../../Assets/images/used.png";
import { apiGet, apiDelete } from "../../../Utilities/userAuth";
import { apiResponse, handleError } from "../../../Utilities/customHelper";
import { getStorage, flushStorage } from "../../../Utilities/storage";
import RecipeLoader from "../../../Shared/Loaders/RecipeLoader";
import toast, { Toaster } from "react-hot-toast";

const Recipe = () => {
  let userInfo = getStorage("userInfo");
  const navigate = useNavigate();
  const { REACT_APP_API_URL } = process.env;
  const [showAll, setShowAll] = useState(false);
  const [recipeDetail, setRecipeDetail] = useState(false);
  const [getRecipes, setGetRecipe] = useState([]);
  const [isLoading, setIsLoading] = useState();

  const [selectedRecipeId, setSelectedRecipeId] = useState(null);

  const selectedRecipe = getRecipes.find((recipe) => recipe.id === selectedRecipeId);

  const cleanText = (text) => {
    return text.replace(/^\s*-\s*\\?"|"/g, "");
  };

  function shortenText(text, maxWords) {
    let words = text.split(" ");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(" ") + "...";
    }
    return text;
  }

  useEffect(() => {
    getAllRecipe();
  }, []);

  let getAllRecipe = () => {
    setIsLoading(true);

    if (userInfo && userInfo.token) {
      let url = `${REACT_APP_API_URL}recipe`;

      const params = {};
      apiGet(url, params, true)
        .then((response) => {
          if (response.data.success === true) {
            let valuesFromDb = response.data.payload.records;
            setGetRecipe(valuesFromDb);
            setIsLoading(false);
          } else {
            if (response.data.success === false) {
              setIsLoading(false);
              console.log(response.data.message);
            }
            apiResponse(response, navigate);
          }
        })
        .catch((error) => {
          handleError();
          setIsLoading(false);
        });
    } else {
      navigate("/login");
    }
  };

  const deleteRecipe = (id) => {
    if (userInfo && userInfo.token) {
      let url = `${REACT_APP_API_URL}recipe/${id}`;
      const params = {};

      apiDelete(url, params, true)
        .then((response) => {
          if (response.data.success === true) {
            // Handle successful deletion, e.g., remove the deleted recipe from the state
            const updatedRecipes = getRecipes.filter((recipe) => recipe.id !== id);
            setGetRecipe(updatedRecipes);
            toast.success(response.data.message);
            getAllRecipe();
            setIsLoading(false);
          } else {
            // toast.success(response.data.message);

            apiResponse(response, navigate);
          }
        })
        .catch((error) => {
          handleError();
        });
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="kitchen-padd">
      {showAll === false ? (
        <div className="recipe-main">
          <Toaster />
          <div className="credit-points">
            <div className="saved-recipe">
              <div className={window.innerWidth > 768 ? "d-flex justify-content-between" : "d-block"}>
                <div>
                  <h5>Your Saved Recipes</h5>
                  <p>Discover and revisit your culinary creations anytime!</p>
                </div>
                <div className={window.innerWidth > 768 ? "p-4" : "pt-4"}>
                  <NavLink to={"/generate-recipe"}>
                    <button className="read-btn">Generate A recipe</button>
                  </NavLink>
                </div>
              </div>
              <div className="d-flex recipe-list tg-scroll-x w-100">
                {isLoading ? (
                  <RecipeLoader />
                ) : getRecipes.length > 0 ? (
                  getRecipes.slice(0, 4).map((item, idx) => (
                    <div key={idx} className="recipe-details">
                      <div className="fork-class">
                        <ReactSVG src={Fork} size={33} />
                      </div>
                      <div className="d-flex justify-content-center">
                        <h5 className="">{item && item.name ? cleanText(item.name) : "Classic Gimlet Cocktail"}</h5>
                      </div>
                      <p>{item && item.recipe ? shortenText(cleanText(item.recipe), 13) : shortenText("We have aimed to show you everything that is relevant to you in one handy place...", 13)}</p>
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <NavLink to={`/recipe-detail/${item.id}`}>
                          <button className="read"> Read Now</button>
                        </NavLink>
                        <button className="remove-btn" onClick={() => deleteRecipe(item.id)}>
                          <ReactSVG src={remove} /> Remove
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  "No Data Found"
                )}

                {getRecipes && getRecipes.length > 4 ? (
                  <div className="recipe-details expand">
                    <NavLink to="/all-recipes">
                      <p>Expand to see All Save Recipes </p>
                      <ReactSVG src={maximise} />
                    </NavLink>
                  </div>
                ) : (
                  " "
                )}
              </div>
            </div>
          </div>
          <div className="want-more">
            <h5>Need More Recipe Credits?</h5>
            <p>Get more cooking inspiration! Click "Buy Now" to purchase additional recipe credits and continue creating delicious dishes</p>
            <Link to={"/payments"}>
              <button type="button" style={{ marginTop: "22px" }}>
                Buy Now
              </button>
            </Link>
          </div>
        </div>
      ) : (
        <div className="all-recipes-list">
          <div className="recipe-main">
            <div className="credit-points">
              <div className="saved-recipe">
                <button className="read-btn" onClick={() => setShowAll(false)}>
                  <ReactSVG src={back} /> Back to Recipe
                </button>
                {recipeDetail === false ? (
                  <div className="d-flex flex-wrap recipe-list">
                    {getRecipes.length > 0
                      ? getRecipes.map((item, idx) => (
                          <div key={idx} className="recipe-details mb-2">
                            <div className="fork-class">
                              <ReactSVG src={Fork} size={33} />
                            </div>
                            <div className="d-flex justify-content-center">
                              <h5 className="">{item && item.name ? cleanText(item.name) : "Classic Gimlet Cocktail"}</h5>
                            </div>
                            <p>{item && item.recipe ? shortenText(cleanText(item.recipe), 13) : shortenText("We have aimed to show you everything that is relevant to you in one handy place...", 13)}</p>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                              <button className="read">Read Now</button>
                              <button className="remove-btn">
                                <ReactSVG src={remove} /> Remove
                              </button>
                            </div>
                          </div>
                        ))
                      : ""}
                  </div>
                ) : (
                  <div className="saved-recipe">
                    <h5>- Pantry Chicken Surprise</h5>
                    <p>
                      "Chicken and Rice Surprise: A delicious and hearty meal that is a twist on a traditional Chicken and Rice dish.\n\nIngredients:\n- 1 cup of white rice\n- 2 chicken breasts\n- 3 eggs\n- 2 tablespoons of oil\n- Salt and black pepper to taste\n\nInstructions:\n\n1. First, let's start by cooking the rice. In a medium sized pot, add 1 cup of white rice and 2 cups of water. Bring it to a boil, then reduce the heat to low and let it simmer for 18 minutes. Fluff the rice with a
                      fork and set it aside.\n\n2. While the rice is cooking, let's prepare our chicken. Cut the chicken breasts into bite size pieces and season them with a pinch of salt and black pepper.\n\n3. In a large pan, heat 2 tablespoons of oil over medium-high heat. Once the oil is hot, add the chicken pieces and cook for about 5-6 minutes, or until they are fully cooked. Remove the chicken from the pan and set it aside.\n\n4. In the same pan, crack 3 eggs and scramble them for 2-3
                      minutes. Once they are cooked, add the cooked rice and chicken to the pan. Mix everything together until the rice is coated in the egg and chicken.\n\n5. Now, it's time to add the seasonings. Add a pinch of salt and black pepper to the pan and mix everything together. You can also add any other seasonings of your choice, like garlic powder or paprika.\n\n6. Let the dish cook for an additional 2-3 minutes, or until everything is heated through.\n\n7. Serve your Chicken and
                      Rice Surprise hot and enjoy the delicious combination of flavors and textures. This dish is similar to a traditional Chicken and Rice, but with a unique twist that will have your taste buds dancing with joy. Enjoy!"
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Recipe;
