import React, { useState, useEffect } from "react";
import "../Recipe.scss";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { apiGet, apiDelete } from "../../../../Utilities/userAuth";
import { apiResponse, handleError } from "../../../../Utilities/customHelper";
import { getStorage, flushStorage } from "../../../../Utilities/storage";

const RecipeDetail = () => {
  let userInfo = getStorage("userInfo");
  const navigate = useNavigate();
  const { REACT_APP_API_URL } = process.env;
  const [getData, setGetData] = useState([]);
  const get_params = useParams();
  let getId = get_params.id;
  getId = getId.split("~");
  const id = parseInt(getId, 10);
  useEffect(() => {
    if (id) {
      if (userInfo && userInfo.token) {
        let url = `${REACT_APP_API_URL}recipe/${id}`;
        const params = {
          id: getId[1],
        };
        apiGet(url, params, true)
          .then((response) => {
            console.log(response.data.payload);
            if (response.status === 200) {
              let valuesFromDb = response.data.payload;
              setGetData(valuesFromDb);
            } else {
              apiResponse(response, navigate);
            }
          })
          .catch((error) => {
            handleError();
          });
      } else {
        navigate("/login");
      }
    }
  }, [get_params]);

  const renderIngredientsList = (items) => {
    return items.split(",").map((item, index) => <li key={index}>{item.trim()}</li>);
  };

  const renderSpicesList = (items) => {
    return items.split(",").map((item, index) => <li key={index}>{item.trim()}</li>);
  };

  const getRecipeTitle = (text) => {
    if (!text) {
      return "No Recipe Available";
    }
    const match = text.match(/\n\n(.+?)\n\n/);
    return match ? match[1] : "No Recipe Available";
  };

  return (
    <>
      <div className="container">
        <div className="recipe-detail">
          <NavLink to="/recipe">
            <button className="read-btn"> Back to Recipe</button>
          </NavLink>
          <div className="title">
            <h1>{getData && getData.name ? getData.name : "-- "} </h1>
          </div>
          <div className="ingredients">
            <h5 className="base-heading">Ingredients:</h5>

            <ul>{getData.ingredients ? renderIngredientsList(getData.ingredients) : <li>No Ingredients</li>}</ul>
          </div>
          <div className="spices">
            <h5 className="base-heading">Spices:</h5>

            <ul>{getData.spices ? renderSpicesList(getData.spices) : <li>No Ingredients</li>}</ul>
          </div>

          <div className="instruction">
            <h5 className="base-heading">Recipe:</h5>
            <p>{getData && getData.recipe ? getData.recipe : "No Recipe Availabe"}</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default RecipeDetail;
