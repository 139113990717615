import React, { useState, useEffect, useRef } from "react";
import { apiGet, apiPost, apiPut } from "../../../Utilities/userAuth";
import { setStorage, getStorage, flushStorage } from "../../../Utilities/storage";
import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { apiResponse, handleError } from "../../../Utilities/customHelper";
import axios from "axios";

const Settings = () => {
  // const axios = require("axios");

  const userInfo = getStorage("userInfo");
  const [editProfile, setEditProfile] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRetype, setShowPasswordRetype] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPassword, setIsLoadingPassword] = useState(false);
  const [profilephoto, setProfilePhoto] = useState("");
  const [defaultCredits, setDefaultCredits] = useState("");
  const [addSelectedOption, setAddSelectedOption] = useState([]);
  const { REACT_APP_API_URL } = process.env;
  const navigate = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { isSubmitted, errors },
  } = useForm({
    shouldFocusError: true,
    keepValues: true,
    defaultValues: {
      //   name: userInfo && userInfo.name ? userInfo.name : "",
    },
  });

  useEffect(() => {
    getUserCredits();
  }, []);
  let getUserCredits = () => {
    setIsLoading(true);

    const url = `${REACT_APP_API_URL}pointsetting`;
    const params = {};

    apiGet(url, params, true)
      .then((response) => {
        if (response.data.success === true) {
          setDefaultCredits(response.data.payload.credits);
          toast.success(response.data.message);
          setIsLoading(false);
        } else {
          apiResponse(response, navigate);
        }
      })
      .catch((error) => {
        console.error("Error updating Credits:", error);
      });
  };

  let updateUserCredits = (data) => {
    setIsLoading(true);
    // const formData = new FormData();
    let formData = JSON.stringify({
      credits: data.credits,
    });
    // console.log("data-==============", data);

    const url = `${REACT_APP_API_URL}pointsetting`;
    apiPut(url, formData, true)
      .then((response) => {
        if (response.data.success === true) {
          toast.success(response.data.message);
          setIsLoading(false);
        } else {
          apiResponse(response, navigate);
        }
      })
      .catch((error) => {
        console.error("Error updating Credits:", error);
      });
  };

  return (
    <>
      <div className="credit-points">
        <Toaster />
        <h3>Settings</h3>
        <p>
          Here, you can set your credit points limit. By default, every 1 USD will give upto <b style={{ color: "#000" }}>{defaultCredits && defaultCredits ? defaultCredits : "10"}</b> credit points.
        </p>
        <div className="mt-3 col-lg-4">
          <form id="settingsForm" onSubmit={handleSubmit(updateUserCredits)}>
            <div className="data-form mt-3">
              <div className="form-row row mb-5">
                <div className="form-group ">
                  <input type="text" className="form-control kitchen-input" id="updateCredit" defaultValue={defaultCredits && defaultCredits ? defaultCredits : ""} placeholder="1 " name="credits" {...register("credits", { required: "This field is required!" })} />
                  <p className="text-danger fs-6 mt-0">{errors.credits ? "This field is required!" : ""}</p>
                </div>
              </div>
            </div>
            <button type="submit" className="btn-custom w-50 mt-3">
              Save Changes
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
export default Settings;
