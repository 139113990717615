import React from "react";
import "./Header.css";
import { FiSearch } from "react-icons/fi";
import { FiBell } from "react-icons/fi";
import { NavLink, useLocation } from "react-router-dom";
import { getStorage } from "../../Utilities/storage";

const Header = () => {
  const location = useLocation();
  const userInfo = getStorage("userInfo");

  const firstName = userInfo.name.split(" ")[0];

  return (
    <div className="header">
      <div className="row">
        <div className="col-sm-9 align-self-center mb-0">
          <h4>Hi, {firstName}</h4>
          <p className="kitchen-text">Welcome to Your Culinary Hub: Manage Recipes, Credits, Payments, and Profile with Ease</p>
        </div>
        {/* <div className="col-sm-3">
          <div className="d-flex justify-content-end">
            <div className="profile-btn">
              <div className="profile-img">
                {" "}
                <img src={require("../../Assets/images/user.png")} />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Header;
