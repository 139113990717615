import React, { useState, useEffect } from "react";
import "./Auth.scss";
import "../../../custom-styles.scss";
import { FaRegEyeSlash } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaRegEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { apiPost } from "../../../../src/Utilities/userAuth";
import toast, { Toaster } from "react-hot-toast";
import { setStorage, getStorage, flushStorage } from "../../../../src/Utilities/storage";
import Logo from "../../../Shared/Logo/Logo";

function ForgotPassword() {
  const { REACT_APP_API_URL } = process.env;
  const [alert, setAlert] = useState();
  const [wrong, setWrong] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      // email: "",
      // password: "",
    },
  });

  const passwordRequest = (data) => {
    setIsLoading(true);
    let url = `${REACT_APP_API_URL}auth/forgot`;
    const params = {
      email: data.email,
    };
    apiPost(url, params)
      .then((response) => {
        // if (parseInt(response.data.code) === 200 && response.data.success === true) {
        console.log("response.data.success", response.data.success);
        if (response.data.success === true) {
          console.log("response.data.message", response.data.message);
          toast.success(response.data.message);
          setIsLoading(false);
          // navigate("/password-success");
        } else if (response.success === false) {
          // toast.error("response.data.message");
        } else if (response.status === 422) {
          toast.error(response.data.message);
          setIsLoading(false);
          if (parseInt(response.status) === 404) {
            toast.error(response.data.message);
          } else if (parseInt(response.status) === 422) {
            let msg = "User not found";
            toast.error(msg);
          }
        }
      })
      .catch((error) => {
        flushStorage();
        setIsLoading(false);
        let msg = "Something is wrong, please try after some time.";
        toast.error(msg);
      });
  };

  return (
    <div className="auth-login">
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <div className="login-main">
          <div className="d-flex justify-content-center "></div>
          <Toaster duration={1000} />
          <h2 className="card-title text-center mb-3">
            <Logo />
          </h2>

          <p className="card-text text-center mb-4">Please enter your Email Address</p>
          <form onSubmit={handleSubmit(passwordRequest)}>
            <div className="mb-3 position-relative">
              <label>Email Address</label>
              <input type="email" className="form-control kitchen-input" name="email" {...register("email", { required: "This field is required" })} placeholder="Enter your email address" />
              <p className="text-danger fs-6 text-start">{errors.email?.message}</p>
            </div>

            <div className="d-grid mb-2">
              <div className="login-btn">
                <button type="submit" className="btn btn-primary btn-lg btn-custom">
                  {isLoading ? "loading......" : "Confirm"}
                </button>
              </div>
            </div>
          </form>
          <div className="d-grid create-account">
            <p>
              Remember your password?{" "}
              <Link to={"/login"}>
                <span className="text-primary"> Go Back</span>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
