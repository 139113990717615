import React, { useState, useEffect } from "react";
import axios from "axios";
import { ReactSVG } from "react-svg";
import { getStorage, flushStorage } from "../../../Utilities/storage";
// import Genrating from "./Components/Generating";
import Generating from "./Components/Generating";
import Search from "./Components/Search";
import ingridients from "../../../Assets/images/GenerateRecipe/ingridients.svg";
import spice from "../../../Assets/images/GenerateRecipe/spice.svg";
import cook from "../../../Assets/images/GenerateRecipe/cook.svg";
import { useNavigate, useLocation, Link, NavLink } from "react-router-dom";
import logo from "../../../Assets/images/GenerateRecipe/logo.svg";
import "./GeneratingRecipe.scss";
import { apiPost } from "../../../Utilities/userAuth";
import toast, { Toaster } from "react-hot-toast";
import Logo from "../../../Shared/Logo/Logo";
import Spinner from "../../../Shared/Loaders/Spinner";

const GenerateRecipe = () => {
  const { REACT_APP_API_URL } = process.env;

  const navigate = useNavigate();
  const location = useLocation();
  const [userInput, setUserInput] = useState("");
  const [selectedModel, setSelectedModel] = useState();
  const [selectedSpices, setSelectedSpices] = useState([]);
  const [show, setShow] = useState(true);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recipe, setRecipe] = useState();
  const [searchVisible, setSearchVisible] = useState(false);
  const [isGenerate, setIsGenerate] = useState(false);
  const [recipeId, setRecipeId] = useState();
  const userInfo = getStorage("userInfo");
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCheckboxChange = (event) => {
    setShowError(false);
    const { value, checked } = event.target;
    setSelectedSpices((currentSelectedSpices) => (checked ? [...currentSelectedSpices, value] : currentSelectedSpices.filter((spice) => spice !== value)));
  };

  // const handleValidation = () => {
  //   if (userInput.trim() === "" || selectedSpices.length === 0) {
  //     console.log("Please fill all the fields before sending the prompt.");
  //     setShowError(true);
  //     setIsGenerate(true);
  //     // setShow(false);
  //     setShow(true);
  //     return;
  //   }
  const handleValidation = () => {
    if (userInput.trim() === "" || selectedSpices.length === 0) {
      setShowError(true);
      // setIsGenerate(true); // Display the error and prevent submission
      return; // Return early if fields are empty
    }

    if (userInfo && userInfo.token) {
      createRecipe();
    } else {
      document.getElementById("loginModaleButton").click();
    }
  };

  const createRecipe = () => {
    if (userInput.trim() === "" || selectedSpices.length === 0) {
      setShowError(true);
      console.log("setShowError inside", userInput);
    }
    let url = `${REACT_APP_API_URL}recipe`;
    setIsLoading(true);
    setShow(false);
    const params = {
      ingredients: userInput,
      method: selectedModel,
      spices: selectedSpices.join(", "),
    };

    apiPost(url, params, true)
      .then((response) => {
        setIsGenerate(false);
        if (response.data.success === true) {
          setIsLoading(false);

          setRecipe(response.data.payload);
          setRecipeId(response.data.payload.id);
          setShow(false);
          setSearchVisible(true);
          toast.success(response.data.message);
        } else {
          if (response.status === 400 || response.status === 401) {
            toast.error(response.data.message);
            setIsLoading(false);
          } else if (response.status === 404) {
            setIsLoading(false);
            setShow(true);
            handleOpenModal();
          } else if (response.success === false) {
            console.log("not enouigh rights");
            handleOpenModal();

            if (response.data.message === "You did not have enough points") {
              document.getElementById("buyCreditButton").click();
              setIsLoading(false);
            } else {
              toast.error(response.data.message);
              setIsLoading(false);
            }
          } else if (response.status === 422) {
            let errors = response.data;
            for (let key in errors) {
              toast.error(errors[key]);
            }
          } else {
            let msg = "Response Error! Please try again later.";
            toast.error(msg);
          }
        }
      })
      .catch((error) => {
        let msg = "Something Wrong! Please try after some time.";
        toast.error(msg);
      })
      .finally((error) => {
        setIsLoading(false);
      });
  };

  const navToLogin = () => {
    document.getElementById("closeModaleBtn").click();
    setTimeout(() => {
      navigate("/login");
    }, 1000);
  };
  const navToPayments = () => {
    document.getElementById("closePaymentBtn").click();
    setTimeout(() => {
      navigate("/payments");
    }, 1000);
  };

  var formattedText;
  if (recipe && recipe.recipe) {
    let modifiedText = recipe.recipe.replace(", Equipment: Pot", "");

    const parts = modifiedText.split(/\n\n|\n/);
    const processedParts = parts.map((part) => {
      part = part.replace(/(Ingredients:)/gi, `<strong style="font-size: 22px;"><hr/><br/>$1<br/></strong>`).replace(/(Instructions:)/gi, `<strong style="font-size: 22px;"><hr/><br/>$1<br/></strong>`);

      if (part.trim().startsWith("-")) {
        part = `<li style="margin-left: 22px;">${part.substring(1).trim()}</li>`; // Remove "-" and wrap with <li>
      }
      return part;
    });
    const filteredParts = processedParts.filter((part) => part.trim() !== "");
    formattedText = `<ul style="list-style-type: circle; padding:0">${filteredParts.join("<br/>")}</ul>`;
  }

  var plainText = recipe && recipe.recipe;

  return (
    <>
      {isGenerate === false && show === true && (
        <>
          <div div className="bdy">
            <Toaster />
            <header>
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="d-flex justify-content-between">
                      <div className="logo">
                        {/* <img src={require("../images/logo.svg")} /> */}
                        <ReactSVG src={logo} />
                      </div>
                      <div className="side-nav d-flex justify-content-between align-items-center">
                        {userInfo && userInfo.token ? (
                          <>
                            <Link to={"/"}>
                              <button className="btn  btn-lg btn-custom-white">Go To Dashboard</button>
                            </Link>
                          </>
                        ) : (
                          <>
                            <Link to={"/login"}>
                              <button className="btn  btn-lg btn-custom-white">Login</button>
                            </Link>
                            <Link to={"/signup"}>
                              <button className="btn  btn-lg btn-custom-white" style={{ marginLeft: "10px" }}>
                                Signup
                              </button>
                            </Link>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="header-content">
                      <h4>Welcome to</h4>
                      <h1>What's in the Kitchen?</h1>
                      <p>Your culinary assistant that crafts recipes from the ingredients you already have. Start by typing in the ingredients in your pantry, add your preferred spices, and choose your cooking method. Hit 'Generate Recipe', and voilà, get a custom recipe idea instantly. It's that simple! Get ready to explore new and exciting dishes with just a few clicks.</p>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          </div>{" "}
          <div className="instr-form">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <form className="row">
                    <div className="col-lg-6">
                      <div className="ingredients">
                        <div className="instr-card">
                          <div className="icon">
                            <ReactSVG src={ingridients} />
                          </div>
                          <div className="content">
                            <h5>Available Ingredients</h5>
                            <p>Comma after each ingredient</p>
                          </div>
                        </div>
                        <input type="text" value={userInput} onChange={(e) => setUserInput(e.target.value)} name="pro" placeholder="Chicken, Rice, Beans, Potatoes" />{" "}
                      </div>
                      <div className="ingredients">
                        <div className="instr-card">
                          <div className="icon">
                            <ReactSVG src={cook} />
                          </div>
                          <div className="content">
                            <h5>Cooking methods</h5>
                            <p>Select All of the Above</p>
                          </div>
                        </div>
                        <select value={selectedModel} onChange={(e) => setSelectedModel(e.target.value)}>
                          <option value="Oven Based,Stovetop Cooking,Countertop Appliances,outdoor or Special Equipment">All The Above</option>
                          <option value="Oven Based">Oven Based</option>
                          <option value="Stovetop Cooking">Stovetop Cooking</option>
                          <option value="Countertop Appliances">Countertop Appliances</option>
                          <option value="outdoor or Special Equipment">Outdoor or Special Equipment</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="ingredients spice">
                        <div className="instr-card">
                          <div className="icon">
                            <ReactSVG src={spice} />
                          </div>
                          <div className="content">
                            <h5>Spices & Seasoning</h5>
                            <p>Check common spices or add your own</p>
                          </div>
                        </div>
                        <input type="text" name="spice" placeholder="Add Your Own Spices" />
                        <div className="extra">
                          <div className="checkbox">
                            <input type="checkbox" id="spice1" onChange={handleCheckboxChange} value="Black Pepper" />
                            <label htmlFor="spice1">Black Pepper</label>
                          </div>
                          <div className="checkbox">
                            <input type="checkbox" id="spice2" name="" onChange={handleCheckboxChange} value="Onion Powder" />
                            <label htmlFor="spice2">Onion Powder</label>
                          </div>
                          <div className="checkbox">
                            <input type="checkbox" id="spice3" name="" onChange={handleCheckboxChange} value="Cinnamon" />
                            <label htmlFor="spice3">Cinnamon</label>
                          </div>
                          <div className="checkbox">
                            <input type="checkbox" id="spice4" name="" onChange={handleCheckboxChange} value="Red Powder " />
                            <label htmlFor="spice4">Red Powder </label>
                          </div>
                          <div className="checkbox">
                            <input type="checkbox" id="spice5" name="" onChange={handleCheckboxChange} value="Garlic Powder" />
                            <label htmlFor="spice5">Garlic Powder </label>
                          </div>
                          <div className="checkbox">
                            <input type="checkbox" id="spice6" name="" onChange={handleCheckboxChange} value="Turmeric" />
                            <label htmlFor="spice6">Turmeric </label>
                          </div>
                          <div className="checkbox">
                            <input type="checkbox" id="spice7" name="" onChange={handleCheckboxChange} value="Chili Flakes" />
                            <label htmlFor="spice7">Chili Flakes </label>
                          </div>
                          <div className="checkbox">
                            <input type="checkbox" id="spice8" name="" onChange={handleCheckboxChange} value="Oregano" />
                            <label htmlFor="spice8">Oregano</label>
                          </div>
                          <div className="checkbox">
                            <input type="checkbox" id="spice9" name="" onChange={handleCheckboxChange} value="Cayenne Pepper" />
                            <label htmlFor="spice9">Cayenne Pepper</label>
                          </div>
                          <div className="checkbox">
                            <input type="checkbox" id="spice10" name="" onChange={handleCheckboxChange} value="Salt" />
                            <label htmlFor="spice10">Salt </label>
                          </div>
                          <div className="checkbox">
                            <input type="checkbox" id="spice11" name="" onChange={handleCheckboxChange} value="Paprika" />
                            <label htmlFor="spice11">Paprika </label>
                          </div>
                          <div className="checkbox">
                            <input type="checkbox" id="spice12" name="" onChange={handleCheckboxChange} value="Thyme" />
                            <label htmlFor="spice12">Thyme </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      {showError && <div className="error-message">Please select the Ingredients & Spices</div>}
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          handleValidation();
                        }}
                        className="genrating-btn"
                        disabled={isLoading}
                      >
                        {"Generate Recipe"}
                        {/* {isLoading && <span className="loader">Loading...</span>} */}
                      </button>

                      <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#loginModale" id="loginModaleButton">
                        Launch login modal
                      </button>
                      <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#buyCredit" id="buyCreditButton">
                        Buy Some Credits
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <Search recipe={recipe} />
            <div className="modal fade" id="loginModale" tabIndex="-1" aria-labelledby="loginModaleLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="text-center">
                      <Logo />
                    </div>
                    <p style={{ fontSize: "20px", textAlign: "center", fontWeight: "600", marginTop: "16px" }}>Please Login to Generate a Recipe</p>
                  </div>
                  <div className="modal-footer d-block" style={{ borderTop: "none", paddingTop: "0px" }}>
                    <button type="button" className="btn btn-secondary d-none" data-bs-dismiss="modal" id="closeModaleBtn">
                      Close
                    </button>

                    <button type="button" className="btn genrating-btn" style={{ marginBottom: "10px" }} onClick={navToLogin}>
                      Click here to Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Buy Credits */}
          </div>
          <div className="modal fade" id="buyCredit" tabIndex="-1" aria-labelledby="buyCreditLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div class="modal-header">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <Logo />
                  </div>
                  <p style={{ fontSize: "16px", textAlign: "justify", fontWeight: "400", marginTop: "16px" }}>
                    Oops! It seems you don't have enough credits. . Purchase more credits to continue creating recipes. <b> Each recipe costs 1 credit. You can get credits on minimum payment of $1.</b> <br />
                    To continue enjoying our services, please consider purchasing additional credits.
                  </p>
                </div>
                <div className="modal-footer d-block" style={{ borderTop: "none", paddingTop: "0px" }}>
                  <button type="button" className="btn btn-secondary d-none" data-bs-dismiss="modal" id="closePaymentBtn">
                    Close
                  </button>

                  <button type="button" className="btn genrating-btn" style={{ marginBottom: "10px", padding: "14px 18px" }} onClick={navToPayments}>
                    {isLoading ? <Spinner /> : "Click here to buy credits"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isLoading ? <Generating /> : <Search recipe={recipe} show={show} setShow={setShow} formattedText={formattedText} recipeId={recipeId} plainText={plainText} setIsLoading={setIsLoading} />}
      {/* <Search recipe={recipe} show={show} setShow={setShow} formattedText={formattedText} plainText={plainText} /> */}

      <div>
        <button type="button" className="btn btn-primary d-none" onClick={handleOpenModal}>
          Launch demo modal
        </button>

        <div className={`modal fade ${showModal ? "show" : ""}`} style={{ display: showModal ? "block" : "none" }} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div class="modal-header" style={{ borderBottomWidth: "0px" }}>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowModal(false)}></button>
              </div>
              <div className="modal-body">
                <div className="text-center">
                  <Logo />
                </div>
                <p style={{ fontSize: "16px", textAlign: "justify", fontWeight: "400", marginTop: "16px" }}>
                  Oops! It seems you don't have enough credits. . Purchase more credits to continue creating recipes. <b> Each recipe costs 1 credit. You can get credits on minimum payment of $1.</b> <br />
                  To continue enjoying our services, please consider purchasing additional credits.
                </p>
              </div>
              <div className="modal-footer d-block" style={{ borderTop: "none", paddingTop: "0px" }}>
                <button type="button" className="btn btn-secondary d-none" data-bs-dismiss="modal" id="closePaymentBtn">
                  Close
                </button>

                <button type="button" className="btn genrating-btn" style={{ marginBottom: "10px", padding: "14px 18px" }} onClick={navToPayments}>
                  {isLoading ? <Spinner /> : "Click here to buy credits"}
                </button>
              </div>
            </div>
          </div>
        </div>

        {showModal && <div className="modal-backdrop fade show"></div>}
      </div>
    </>
  );
};

export default GenerateRecipe;
