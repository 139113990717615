import React, { useState, useEffect } from "react";
import "../Recipe.scss";
import { ReactSVG } from "react-svg";
import { Link, NavLink, useNavigate } from "react-router-dom";

import Fork from "../../../../Assets/svgs/fork.svg";
import remove from "../../../../Assets/svgs/remove.svg";
import maximise from "../../../../Assets/svgs/maximize.svg";
import back from "../../../../Assets/svgs/back.svg";
import used from "../../../../Assets/images/used.png";
import { apiGet, apiDelete } from "../../../../Utilities/userAuth";
import { apiResponse, handleError } from "../../../../Utilities/customHelper";
import { getStorage, flushStorage } from "../../../../Utilities/storage";
import toast, { Toaster } from "react-hot-toast";

const AllRecipes = () => {
  let userInfo = getStorage("userInfo");
  const navigate = useNavigate();
  const { REACT_APP_API_URL } = process.env;
  const [showAll, setShowAll] = useState(false);
  const [recipeDetail, setRecipeDetail] = useState(false);
  const [getRecipes, setGetRecipe] = useState([]);
  const [isLoading, setIsLoading] = useState();

  function shortenText(text, maxWords) {
    let words = text.split(" ");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(" ") + "...";
    }
    return text;
  }

  const cleanText = (text) => {
    return text.replace(/^\s*-\s*\\?"|"/g, "");
  };

  const showDetailedView = () => {
    setShowAll(true);
    setRecipeDetail(true);
  };
  const showAllRecipe = () => {
    setRecipeDetail(false);

    setShowAll(true);
  };

  useEffect(() => {
    getAllRecipe();
  }, []);

  let getAllRecipe = () => {
    setIsLoading(true);

    if (userInfo && userInfo.token) {
      let url = `${REACT_APP_API_URL}recipe`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: userInfo.token,
      };
      const params = {};
      apiGet(url, params, true)
        .then((response) => {
          if (response.data.success === true) {
            let valuesFromDb = response.data.payload.records;
            setGetRecipe(valuesFromDb);
            setIsLoading(false);
          } else {
            apiResponse(response, navigate);
          }
        })
        .catch((error) => {
          handleError();
        });
    } else {
      navigate("/login");
    }
  };

  const deleteRecipe = (id) => {
    if (userInfo && userInfo.token) {
      let url = `${REACT_APP_API_URL}recipe/${id}`;
      const headers = {
        "Content-Type": "application/json",
        Authorization: userInfo.token,
      };

      apiDelete(url, headers, true)
        .then((response) => {
          if (response.data.success === true) {
            // Handle successful deletion, e.g., remove the deleted recipe from the state
            const updatedRecipes = getRecipes.filter((recipe) => recipe.id !== id);
            setGetRecipe(updatedRecipes);
            toast.success(response.data.message);
            setIsLoading(false);
          } else {
            apiResponse(response, navigate);
          }
        })
        .catch((error) => {
          handleError();
        });
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="container">
      <div className="all-recipes-list">
        <div className="recipe-main">
          <Toaster />
          <div className="credit-points">
            <div className="saved-recipe">
              <NavLink to="/recipe">
                <button className="read-btn">
                  <ReactSVG src={back} /> Back to Recipe
                </button>
              </NavLink>
              <div className="d-flex flex-wrap recipe-list">
                {isLoading
                  ? "loading..."
                  : getRecipes.length > 0
                  ? getRecipes.map((item, idx) => (
                      <div key={idx} className="recipe-details mb-2">
                        <div className="fork-class">
                          <ReactSVG src={Fork} size={33} />
                        </div>
                        <div className="d-flex justify-content-center">
                          <h5 className="">{item && item.name ? cleanText(item.name) : "Classic Gimlet Cocktail"}</h5>
                        </div>
                        <p>{item && item.recipe ? shortenText(cleanText(item.recipe), 13) : shortenText("We have aimed to show you everything that is relevant to you in one handy place...", 13)}</p>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <NavLink to={`/recipe-detail/${item.id}`}>
                            <button className="read"> Read Now</button>
                          </NavLink>
                          <button className="remove-btn" onClick={() => deleteRecipe(item.id)}>
                            <ReactSVG src={remove} /> Remove
                          </button>
                        </div>
                      </div>
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AllRecipes;
